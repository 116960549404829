import { Formik } from 'formik';
import {
  ProFormCreateModalQuery,
  ProFormCreateProViewMutation,
  ProFormUpdateProPresentationMutation,
  ProPresentationSetInput,
  ProViewInsertInput,
} from 'generated/graphql';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ToastError, ToastSuccess } from 'utils';
import { ProFormCreateFields } from './pro-form-create-fields.type';
import { ProFormCreateModalProps } from './pro-form-create-modal-repository';
import ProFormCreateModalView from './pro-form-create-modal-view';
import { proFormCreateValidationSchema } from './pro-form-create-validation-schema';

type ProFormCreateModalContainerProps = ProFormCreateModalProps & {
  fetchProPresentation: (id: number) => Promise<void>;
  proPresentationId?: number;
  proPresentation?: ProFormCreateModalQuery['proPresentationByPk'];
  createProView: (
    proView: ProViewInsertInput,
  ) => Promise<ProFormCreateProViewMutation['insertProViewOne']>;
  updateProPresentation: (
    id: number,
    proPresentation: ProPresentationSetInput,
  ) => Promise<
    ProFormUpdateProPresentationMutation['updateProPresentationByPk']
  >;
  onProCreated?: (proViewId: number) => void;
};

const ProFormCreateModalContainer: FunctionComponent<
  ProFormCreateModalContainerProps
> = ({
  fetchProPresentation,
  createProView,
  updateProPresentation,
  proPresentationId,
  proPresentation,
  onProCreated,
  onClose,
  ...rest
}) => {
  const [step, setStep] = useState(1);
  const nextStep = useCallback(() => {
    setStep(step + 1);
  }, [step]);

  const previousStep = useCallback(() => {
    setStep(step - 1);
  }, [step]);

  useEffect(() => {
    if (proPresentationId) {
      fetchProPresentation(proPresentationId).catch((error) => {
        ToastError('Erreur', "Impossible d'ajouter une zone");
        throw error;
      });
    }
  }, [proPresentationId]);

  const onSubmit = useCallback(
    async (values: ProFormCreateFields): Promise<void> => {
      if (proPresentationId) {
        await updateProPresentation(
          proPresentationId,
          values.proPresentation,
        ).catch((error) => {
          ToastError('Erreur', "Impossible d'enregistrer la fiche");
          console.error(error);
          throw error;
        });
      }

      const createdProView = await createProView({
        // Update proPresentation
        proPresentationId,

        // Create proPresentation
        proPresentation: !proPresentationId
          ? {
              data: {
                name: values.proPresentation.name.trim(),
                mainTradeId: values.proPresentation.mainTradeId ?? undefined,
                secondaryTradeId:
                  values.proPresentation.secondaryTradeId ?? undefined,
                slug: values.proPresentation.slug,
              },
            }
          : undefined,
        bilikZoneId: values.bilikZoneId ?? undefined,
        trades: {
          data: values.trades.map((tradeId) => ({
            tradeId,
          })),
        },
        emailTarget: values.emailTarget,
        callTrackingTarget: {
          name: null,
          telephone: null,
        },
        isCallAlertActivated: true,
        isCallRecordable: true,
        status: 'draft',
      }).catch((error) => {
        ToastError('Erreur', "Impossible d'enregistrer la fiche");
        throw error;
      });

      if (proPresentationId) {
        ToastSuccess('Succès', 'Nouvelle zone créée !');
      } else {
        ToastSuccess('Succès', 'Fiche créée !');
      }

      if (onClose) {
        onClose();
      }

      if (onProCreated && createdProView?.id) {
        onProCreated(createdProView.id);
      }
    },
    [proPresentationId],
  );

  const exceptZones = useMemo(
    () =>
      proPresentation
        ? proPresentation.proViews.map((proView) => proView.bilikZoneId)
        : undefined,
    [proPresentation],
  );

  const initialValues: ProFormCreateFields = useMemo(
    () => ({
      bilikZoneId: null,
      proPresentation: {
        name: proPresentation?.name ?? '',
        slug: proPresentation?.slug ?? '',
        mainTradeId: proPresentation?.mainTradeId ?? undefined,
        secondaryTradeId: proPresentation?.secondaryTradeId ?? undefined,
      },
      trades: [
        ...(proPresentation?.mainTradeId ? [proPresentation.mainTradeId] : []),
        ...(proPresentation?.secondaryTradeId
          ? [proPresentation.secondaryTradeId]
          : []),
      ],
      emailTarget: '',
      accounts: [],
      proPeople: [],
    }),
    [proPresentation],
  );

  // Loading state in case of create zone
  if (proPresentationId && !proPresentation) {
    return null;
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={proFormCreateValidationSchema(
        proPresentationId ? 'create-zone' : 'create',
      )}
    >
      <ProFormCreateModalView
        step={step}
        nextStep={nextStep}
        previousStep={previousStep}
        exceptZones={exceptZones}
        proPresentationName={proPresentation?.name}
        onClose={onClose}
        action={proPresentationId ? 'add-zone' : 'create-pro'}
        {...rest}
      />
    </Formik>
  );
};

export default ProFormCreateModalContainer;
