import env from 'env';
import { useCurrentBilikPerson } from 'hooks/use-current-bilik-person/use-current-bilik-person';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Icon, Image, Item, Menu } from 'semantic-ui-react';
import styled from 'styled-components';
import { useKeycloak } from '@react-keycloak/web';

const MenuBrand = styled.div`
  width: 230px;
  color: #fff;
  line-height: 50px;
  text-align: center;
  font-weight: 300;
  font-size: 20px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

  span {
    font-weight: 700;
  }
`;

const Profile = styled.div`
  height: 175px;
  padding: 10px;
  text-align: center;
  background: #3c8dbc;

  img {
    height: 90px;
    width: 90px;
    border: 3px solid;
    border-color: rgba(255, 255, 255, 0.2);
  }

  p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 17px;
    margin-top: 10px;
  }
`;

const MainHeader: FunctionComponent = () => {
  const { currentBilikPerson } = useCurrentBilikPerson();
  const { keycloak } = useKeycloak();

  return (
    <Menu>
      <Link to="/pros">
        <MenuBrand>
          <span>Admin</span>BLK
        </MenuBrand>
      </Link>
      <Menu.Menu position="right">
        <Item>{env.TAG_VERSION}</Item>
        <Dropdown
          icon={null}
          item
          trigger={
            <div className="text">
              <Image
                src={
                  currentBilikPerson?.avatarHash
                    ? `${env.API_URL}/images/${currentBilikPerson?.avatarHash}/full/0,0/0/default.png`
                    : '/lazy-white.png'
                }
                circular
              />
              {currentBilikPerson?.givenName} {currentBilikPerson?.familyName}
            </div>
          }
        >
          <Dropdown.Menu direction="left">
            <Profile>
              <Image
                centered
                src={
                  currentBilikPerson?.avatarHash
                    ? `${env.API_URL}/images/${currentBilikPerson?.avatarHash}/full/0,0/0/default.png`
                    : '/lazy-white.png'
                }
                circular
              />
              <p>
                {currentBilikPerson?.givenName} {currentBilikPerson?.familyName}
                <br />
                <small>{currentBilikPerson?.email}</small>
              </p>
            </Profile>
            <Dropdown.Item
              onClick={(): void => {
                keycloak.logout();
              }}
            >
              <Icon name="power off" />
              Déconnexion
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    </Menu>
  );
};

export default MainHeader;
