import { useCheckProTradeAssociationQuery } from 'generated/graphql';
import { useEffect, useState } from 'react';

export const useCheckSecondaryTradeAssociation = (
  tradeIds: number[],
  mainTradeId: number | null,
  secondaryTradeId: number | null,
) => {
  if (!mainTradeId) return [];

  const { data } = useCheckProTradeAssociationQuery({
    variables: {
      mainTradeId,
    },
  });

  const [recommendations, setRecommendations] = useState<
    {
      secondaryTradeName: string | undefined;
      tradeGuideName: string | undefined;
    }[]
  >([]);

  // Check if pro mainTrade is in tradeGuide
  // If true, check if secondaryTrade is correct
  // Some examples:
  // Case 1: Pro has "Charpentier" as mainTrade (matching tradeGuide "Charpentier couvreur")
  // he has "Couvreur" as trade but not as secondaryTrade -> the secondaryTrade is not correct (should be "Couvreur")
  // Case 2: pro has "Façadier" as mainTrade (matching tradeGuide "Facadier / ITE")
  // he has NOT "Isolation par l'extérieur" as trade -> the secondaryTrade is correct (no need to be "Isolation par l'extérieur")
  useEffect(() => {
    const trades = data?.trade;
    const tradesGuidesByMainTrade =
      data?.tradeByPk?.tradeGuidesByFirstTrade?.concat(
        data?.tradeByPk?.tradeGuidesBySecondTrade || [],
      );

    if (data) {
      if (tradesGuidesByMainTrade?.length) {
        // Get tradeGuide tradeIds that are not matching mainTradeId
        const tradeGuideNotMainTradeIds = tradesGuidesByMainTrade?.map(
          (mainTradeTradeGuide) => {
            // Find the other trade of tradeGuide (secondTrade if mainTrade is firstTrade, firstTrade if mainTrade is secondTrade)
            const isMainTradeMatchTradeGuideFirstTrade =
              mainTradeId === mainTradeTradeGuide.firstTradeId;
            const tradeGuideOtherTradeId = isMainTradeMatchTradeGuideFirstTrade
              ? mainTradeTradeGuide.secondTradeId
              : mainTradeTradeGuide.firstTradeId;

            return tradeGuideOtherTradeId;
          },
        );

        const recommendedSecondaryTradeIds = tradeGuideNotMainTradeIds.filter(
          (tradeGuideOtherTradeId) =>
            // check if the other trade of tradeGuide is matching one of pro trades
            tradeIds.includes(tradeGuideOtherTradeId),
        );

        // If there are recommendedSecondaryTradeIds and is not already
        // set as secondaryTrade, set recommendations
        if (
          recommendedSecondaryTradeIds &&
          !recommendedSecondaryTradeIds.includes(secondaryTradeId || 0)
        ) {
          const recommendations = recommendedSecondaryTradeIds.map(
            (recommendedSecondaryTradeId) => {
              const secondaryTradeName = trades?.find(
                (trade) => trade.id === recommendedSecondaryTradeId,
              )?.name;

              const tradeGuideName = tradesGuidesByMainTrade.find(
                (mainTradeTradeGuide) => {
                  // Check if the firstTradeId or secondTradeId matches the recommendedSecondaryTradeId
                  const isFirstTradeIdMatch =
                    mainTradeTradeGuide.firstTradeId ===
                    recommendedSecondaryTradeId;
                  const isSecondTradeIdMatch =
                    mainTradeTradeGuide.secondTradeId ===
                    recommendedSecondaryTradeId;

                  // Return true if either of the IDs match
                  return isFirstTradeIdMatch || isSecondTradeIdMatch;
                },
              )?.name;

              return {
                secondaryTradeName,
                tradeGuideName,
              };
            },
          );

          setRecommendations(recommendations);
        }
        return;
      }
      setRecommendations([]);
    }
  }, [data?.tradeByPk, tradeIds, mainTradeId, secondaryTradeId]);

  return recommendations;
};
