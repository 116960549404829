import React, { FunctionComponent } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { Serie } from '@nivo/line';
import SliceTooltip from './slice-tooltip/slice-tooltip';

type DefaultLineChartProps = {
  data: Serie[];
  colors: string[];
};

const DefaultLineChart: FunctionComponent<DefaultLineChartProps> = ({
  data,
  colors,
}) => {
  return (
    <div style={{ height: 500, textAlign: 'center' }}>
      <ResponsiveLine
        data={data}
        margin={{ top: 30, right: 30, bottom: 60, left: 50 }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 0,
          max: 'auto',
        }}
        pointSize={3}
        pointColor="#FFFFFF"
        pointBorderWidth={6}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="data.yFormatted"
        pointLabelYOffset={-12}
        useMesh={true}
        lineWidth={3}
        colors={colors}
        enableSlices="x"
        sliceTooltip={({ slice }) => <SliceTooltip slice={slice} evolution />}
        axisLeft={{ tickPadding: 12 }}
        axisBottom={{ tickPadding: 12, tickRotation: 45 }}
        enableCrosshair={false}
        legends={[
          {
            anchor: 'top-left',
            direction: 'row',
            translateX: -37,
            translateY: -35,
            itemWidth: 130,
            itemHeight: 20,
            symbolSize: 12,
            symbolShape: 'square',
          },
        ]}
      />
    </div>
  );
};
export default DefaultLineChart;
