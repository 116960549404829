import FormField from 'components/form-field/form-field';
import { Formik } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import { Button, Icon, Label, Modal, Form } from 'semantic-ui-react';
import * as Yup from 'yup';

type ProBouncedSolicitationViewProps = {
  resendSolicitationsToPro: (emailTarget: string) => Promise<void>;
  isExplanationModalOpen: boolean;
  setIsExplanationModalOpen: (boolean) => void;
  accountModalOpen: boolean;
  setAccountModalOpen: (boolean) => void;
  isActionInProgress: boolean;
  emailTarget: string;
  reactivateEmailInPostmark: () => Promise<void>;
};

export const ProBouncedSolicitationView: FunctionComponent<
  ProBouncedSolicitationViewProps
> = ({
  isActionInProgress,
  isExplanationModalOpen,
  accountModalOpen,
  resendSolicitationsToPro,
  emailTarget,
  setIsExplanationModalOpen,
  setAccountModalOpen,
  reactivateEmailInPostmark,
}) => {
  const [isEditEmailTargetModal, setIsEditEmailTargetModal] = useState(false);

  return (
    <>
      <Label
        as="a"
        onClick={(): void => setIsExplanationModalOpen(true)}
        color="red"
      >
        <>
          <Icon name="exclamation triangle" />
          Important : Cliquez-ici ! (demande non transmise au pro)
        </>
      </Label>
      <Modal
        size="tiny"
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={isExplanationModalOpen}
      >
        <Modal.Header>Action à faire</Modal.Header>
        <Modal.Content>
          <p>
            Cette demande n&apos;a pas été transmise au pro. Pour réparer ce
            problème tu as 2 options :
          </p>

          <Button
            loading={isActionInProgress}
            disabled={isActionInProgress}
            basic
            style={{ marginBottom: '8px' }}
            onClick={(): void => {
              setIsEditEmailTargetModal(true);
            }}
            color="red"
          >
            <Icon name="pencil" />
            Modifier l&apos;adresse mail du pro ET renvoyer les demandes non
            reçues
          </Button>

          <Button
            loading={isActionInProgress}
            disabled={isActionInProgress}
            onClick={reactivateEmailInPostmark}
            basic
            color="red"
          >
            <Icon name="mail" />
            L&apos;adresse mail du pro fonctionne de nouveau, renvoyer
            simplement les demandes non reçues
          </Button>
        </Modal.Content>
        <Modal.Actions>
          <Button
            type="button"
            icon="remove"
            content="Fermer"
            onClick={(): void => setIsExplanationModalOpen(false)}
          />
        </Modal.Actions>
      </Modal>

      <Formik
        validationSchema={Yup.object().shape({
          emailTarget: Yup.string()
            .email('Email invalide !')
            .required('Email requis !'),
        })}
        validateOnChange={true}
        initialValues={{ emailTarget: emailTarget }}
        onSubmit={async (values, actions): Promise<void> => {
          await resendSolicitationsToPro(values.emailTarget);

          actions.setSubmitting(false);
        }}
      >
        {(props): React.ReactElement => (
          <Modal
            size="tiny"
            closeOnEscape={false}
            closeOnDimmerClick={false}
            open={isEditEmailTargetModal}
          >
            <Modal.Header>Editer le mail cible</Modal.Header>
            <Modal.Content>
              <Form onReset={props.handleReset} onSubmit={props.handleSubmit}>
                <FormField
                  type="email"
                  name="emailTarget"
                  label="Email cible"
                  placeholder="Ex: example@email.fr"
                  helpText="Adresse email recevant tous les mails issus de notre plateforme (demande mail, notification d'un nouvel avis)"
                  required
                />
                <Button
                  primary
                  type="submit"
                  loading={props.isSubmitting}
                  disabled={props.isSubmitting}
                >
                  <Icon name="mail" />
                  Envoyer
                </Button>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button
                type="button"
                icon="remove"
                content="Fermer"
                onClick={(): void => setIsEditEmailTargetModal(false)}
              />
            </Modal.Actions>
          </Modal>
        )}
      </Formik>
    </>
  );
};
