import React, {
  Fragment,
  FunctionComponent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Container,
  Dropdown,
  Grid,
  Icon,
  Label as LabelUi,
  List,
  Loader,
  Popup,
  Table,
} from 'semantic-ui-react';
import { useProContractQuery } from 'generated/graphql';
import { apiClient } from 'axios-client';
import DocumentPreviewModal from 'components/document-preview-modal/document-preview-modal';
import { DocumentSendFormModal } from '../document-send-form-modal';
import { ProContractFormModal } from './pro-contract-form-modal';
import { ProContractFormFields } from './pro-contract-form-modal/pro-contract-form-fields';
import {
  addDays,
  differenceInDays,
  endOfMonth,
  format,
  getDaysInMonth,
  max,
} from 'date-fns';
import env from 'env';
import ConfirmModal from 'components/confirm-modal/confirm-modal';
import { getToken, ToastError, ToastSuccess } from 'utils';
import { fr } from 'date-fns/locale';

const outdatedMessage =
  "Le contrat ne peut plus être signé car la date d'entrée en vigueur est dépassée (vous devez modifier/regénérer le contrat)";

type ProContractProps = {
  proOrganizationId: number;
  proViewId: number;
  receiptEmailSuggestions?: string[];
  proPersonSuggestions?: {
    telephone: string;
    givenName: string;
    familyName: string;
  }[];
  key?: string;
};
type ProContractConfiguration = ProContractFormFields & {
  proReceiptEmail?: string;
};

type ContractEvent = {
  type: string;
  date: string;
  metadata: any;
};

const ProContract: FunctionComponent<ProContractProps> = ({
  proOrganizationId,
  proViewId,
  receiptEmailSuggestions,
  proPersonSuggestions,
  key,
}) => {
  const [previewModalState, setPreviewModalState] = useState<{
    open: boolean;
    document?: {
      mimeType: string;
      url: string;
      name: string;
      options?: unknown;
    };
  }>({ open: false });

  const { data, loading, refetch } = useProContractQuery({
    variables: {
      id: proViewId,
    },
  });

  useEffect(() => {
    refetch();
  }, [key]);

  const [proContractFormModalState, setProContractFormModalState] = useState<{
    open: boolean;
    update?: boolean;
    isProPublished: boolean;
  }>({
    open: false,
    update: false,
    isProPublished: false,
  });

  const [documentSendFormModalState, setDocumentSendFormModalState] = useState<{
    open: boolean;
  }>({
    open: false,
  });

  const proContract = useMemo(() => {
    if (!data?.proView) return null;

    const proView = data.proView;

    const contractEvents: ContractEvent[] = proView.contractEvents;

    const dates = {
      dateProSent: contractEvents?.find((event) => event.type === 'ProSent')
        ?.date,
      dateBilikSent: contractEvents?.find((event) => event.type === 'BilikSent')
        ?.date,
      dateProSigned: contractEvents?.find((event) => event.type === 'ProSigned')
        ?.date,
      dateBilikSigned: contractEvents?.find(
        (event) => event.type === 'BilikSigned',
      )?.date,
    };

    // Check on datePublished to know if the pro has already been published.
    // (catch drafted pro that has been published at leat one time)
    const isProPublished = !!proView.datePublished;

    setProContractFormModalState({
      ...proContractFormModalState,
      isProPublished,
    });

    return {
      ...dates,
      isProPublished,
      mainCityCode: proView.bilikZone.mainCityCode,
      mainTrade: proView.proPresentation?.mainTrade,
      configuration: proView.contractConfiguration as ProContractConfiguration,
      isEditable: !dates.dateProSigned,
      isContractSigned: dates.dateBilikSigned && dates.dateProSigned,
      documentKey: `pro-organizations-documents/${proOrganizationId}/contrat-${proView.id}.pdf`,
    };
  }, [data]);

  const missingFields: string[] = useMemo(() => {
    if (!data?.proView) return [];

    const proView = data.proView;
    const proOrganization = proView.proPresentation.proOrganization;

    const missingFields: string[] = [];

    if (!proView.bilikZone) {
      missingFields.push('zone');
    }

    if (!proView.proPresentation?.mainTrade) {
      missingFields.push('métier principal');
    }

    if (!proOrganization) {
      missingFields.push('entreprise');

      return missingFields;
    }

    if (
      proOrganization &&
      Object.values(proOrganization).some((value) => value === null)
    ) {
      missingFields.push("le formulaire de l'entreprise n'est pas complet");
    }

    return missingFields;
  }, [data]);

  const prorate = useMemo(() => {
    if (!proContract?.configuration) return null;

    const { configuration } = proContract;

    const now = new Date();

    const startDate = proContract.configuration.startDate
      ? max([new Date(proContract.configuration.startDate), now])
      : now;

    // Apply prorate when :
    // - Pro has never been published
    // - Date is before the 25th and after 4th of the month
    const shouldApplyProrate =
      startDate.getDate() < 25 &&
      startDate.getDate() > 4 &&
      !proContract.isProPublished;

    if (!shouldApplyProrate) return null;

    const prorateDays = differenceInDays(endOfMonth(startDate), startDate);
    const prorateStartDate = addDays(startDate, 1); // Do not include the current day in the prorate

    const baseAmount = configuration.discounts?.length
      ? configuration.discounts[0].appliedAmount
      : configuration.subscriptionAmount;

    const amount =
      Math.floor(
        ((prorateDays * 1) / getDaysInMonth(prorateStartDate)) *
          baseAmount *
          100,
      ) / 100;

    return {
      month: format(prorateStartDate, 'MMMM yyyy', { locale: fr }),
      days: prorateDays,
      amount,
    };
  }, [proContract]);

  // If contract is dalayed and current date is after the delayed date then the contract is outdated
  // So the contract can't be signed and need to be regenerated
  const isContractOutdated = useMemo(
    () =>
      (proContract?.configuration?.startDate &&
        new Date() > new Date(proContract.configuration.startDate)) ??
      false,
    [proContract],
  );

  const shouldWaitBilling = useMemo(
    () => proContract?.isProPublished && new Date().getDate() < 5,
    [proContract],
  );

  const signBilikButtonContent = useMemo(
    () => (
      <>
        <Icon name="mail" />
        {proContract?.dateBilikSent ? 'Renvoyer' : 'Envoyer'} à Bilik pour
        signature
      </>
    ),
    [proContract?.dateBilikSent],
  );

  if (loading) {
    return (
      <Loader
        style={{ marginTop: '50px' }}
        size="large"
        active
        inline="centered"
      >
        Chargement...
      </Loader>
    );
  }

  if (!proContract) {
    return <div>Erreur</div>;
  }

  return (
    <>
      {proContract.configuration ? (
        <>
          <Table basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Fiche</Table.HeaderCell>
                <Table.HeaderCell>Configuration</Table.HeaderCell>
                <Table.HeaderCell>Dates</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <p>
                    Zone : {proContract.mainCityCode}
                    <br />
                    Catégorie : {proContract.mainTrade?.label}
                  </p>
                </Table.Cell>
                <Table.Cell>
                  <p>
                    Droit d&apos;entrée :{' '}
                    {proContract.configuration?.entranceFee ? (
                      <>
                        {proContract.configuration.entranceFee.appliedAmount}€{' '}
                        {proContract.configuration.entranceFee
                          .monthSpreadAmount > 1 &&
                          `sur ${proContract.configuration.entranceFee.monthSpreadAmount} mois`}
                      </>
                    ) : (
                      <>
                        Aucun{' '}
                        {proContract.isProPublished
                          ? '(renouvellement de contrat)'
                          : ''}
                      </>
                    )}
                    <br />
                    Mensualité de base :{' '}
                    {proContract.configuration?.subscriptionAmount !==
                      undefined && (
                      <>{proContract.configuration.subscriptionAmount}€</>
                    )}
                  </p>
                  {prorate ? (
                    <p>
                      <u>
                        Prorata appliqué{' '}
                        {!proContract.dateBilikSigned
                          ? "(si signé aujourd'hui) "
                          : ''}
                        :
                      </u>
                      <br />
                      {prorate.amount}€ ({prorate.month} - {prorate.days} jours)
                    </p>
                  ) : null}
                  {(proContract.configuration.discounts?.length ?? 0) > 0 && (
                    <>
                      <p style={{ margin: 0 }}>
                        <u>Palier(s) :</u>
                      </p>
                      <ul style={{ margin: 0, paddingLeft: 18 }}>
                        {proContract.configuration.discounts?.map(
                          (discount, index) => (
                            <li
                              style={{ lineHeight: '16px' }}
                              key={`discount-${index}`}
                            >
                              {discount.appliedAmount}€ pendant{' '}
                              {discount.occurencesNumber} mois
                            </li>
                          ),
                        )}
                        <li style={{ lineHeight: '16px' }}>
                          {proContract.configuration.subscriptionAmount}€
                        </li>
                      </ul>
                    </>
                  )}
                </Table.Cell>
                <Table.Cell width={5}>
                  <List verticalAlign="middle">
                    <List.Item>
                      <List.Content floated="right">
                        {proContract.configuration.startDate ||
                        proContract.dateBilikSigned ? (
                          <LabelUi color="grey">
                            {format(
                              max([
                                new Date(
                                  proContract.configuration.startDate ??
                                    '1970-01-01',
                                ),
                                new Date(
                                  proContract.dateBilikSigned ?? '1970-01-01',
                                ),
                              ]),
                              'dd/MM/yyyy',
                            )}
                          </LabelUi>
                        ) : (
                          <LabelUi color="grey">N/A</LabelUi>
                        )}
                      </List.Content>
                      <List.Content>Entrée en vigueur</List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content floated="right">
                        {proContract.dateProSigned ? (
                          <LabelUi color="green">
                            {format(
                              new Date(proContract.dateProSigned),
                              'dd/MM/yyyy',
                            )}
                          </LabelUi>
                        ) : (
                          <>
                            {proContract.dateProSent ? (
                              <LabelUi color="orange">
                                Envoyé le{' '}
                                {format(
                                  new Date(proContract.dateProSent),
                                  'dd/MM à HH:mm',
                                )}
                              </LabelUi>
                            ) : (
                              <LabelUi color="red">À signer</LabelUi>
                            )}
                          </>
                        )}
                      </List.Content>
                      <List.Content>Signature du pro</List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Content floated="right">
                        {proContract.dateBilikSigned ? (
                          <LabelUi color="green">
                            {format(
                              new Date(proContract.dateBilikSigned),
                              'dd/MM/yyyy',
                            )}
                          </LabelUi>
                        ) : (
                          <>
                            {proContract.dateBilikSent ? (
                              <LabelUi color="orange">
                                Envoyé le{' '}
                                {format(
                                  new Date(proContract.dateBilikSent),
                                  'dd/MM à HH:mm',
                                )}
                              </LabelUi>
                            ) : (
                              <LabelUi color="red">À signer</LabelUi>
                            )}
                          </>
                        )}
                      </List.Content>
                      <List.Content>Signature de Bilik</List.Content>
                    </List.Item>
                  </List>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Container fluid textAlign="center">
            <Dropdown
              text="PDF"
              labeled
              button
              floating
              direction="left"
              className="icon"
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setPreviewModalState({
                      open: true,
                      document: {
                        mimeType: 'application/pdf',
                        url: `${env.API_URL}/attachment/object-by-key?key=${proContract.documentKey}`,
                        name: proContract.documentKey.split('/').pop() ?? '',
                        options: {
                          httpHeaders: {
                            Authorization: `Bearer ${getToken()}`,
                          },
                        },
                      },
                    });
                  }}
                >
                  <Icon name="eye" />
                  Voir
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    // Add watermark when contract has no signature
                    const downloadUrl = proContract.isEditable
                      ? `${env.API_URL}/form/pdf/watermark-token/projet?key=${
                          proContract.documentKey
                        }&token=${getToken()}`
                      : `${env.API_URL}/attachment/object-by-key-token?key=${
                          proContract.documentKey
                        }&token=${getToken()}`;

                    window.open(downloadUrl, '_blank');
                  }}
                >
                  <Icon name="download" />
                  Télécharger
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {proContract.isEditable ? (
              <Button
                type="button"
                onClick={(): void => {
                  setProContractFormModalState({
                    ...proContractFormModalState,
                    open: true,
                    update: true,
                  });
                }}
              >
                <Icon name="pencil alternate" />
                Modifier
              </Button>
            ) : (
              <ConfirmModal
                header={'Pour information'}
                content={
                  <p>
                    La regénération du contrat écrasera les données du précédent
                    contrat, y compris les signatures. Continuer ?
                  </p>
                }
                onConfirm={(): void => {
                  setProContractFormModalState({
                    ...proContractFormModalState,
                    open: true,
                    update: false,
                  });
                }}
                trigger={
                  <Button type="button" color="orange">
                    <Icon name="redo" />
                    Regénérer un nouveau contrat
                  </Button>
                }
              />
            )}
            {!proContract.isContractSigned && (
              <>
                <Button
                  icon="refresh"
                  onClick={() => refetch()}
                  style={{ minWidth: '45px', minHeight: '32px' }}
                  type="button"
                />
                <Popup
                  content={
                    <ul style={{ padding: 0, paddingLeft: '16px', margin: 0 }}>
                      {isContractOutdated && <li>{outdatedMessage}</li>}
                      {!!proContract.dateProSigned && (
                        <li>Le pro a déjà signé le contrat</li>
                      )}
                    </ul>
                  }
                  disabled={!isContractOutdated && !proContract.dateProSigned}
                  trigger={
                    // ! Dont remove the span below (it's a workaround for Popup not working with Button disabled )
                    <span>
                      <Button
                        primary
                        disabled={
                          isContractOutdated || !!proContract.dateProSigned
                        }
                        onClick={() => {
                          setDocumentSendFormModalState({
                            open: true,
                          });
                        }}
                      >
                        <Icon name="mail" />
                        {proContract.dateProSent ? 'Renvoyer' : 'Envoyer'} au
                        pro pour signature
                      </Button>
                    </span>
                  }
                />
                {/*
                  I am forced to render separately the disabled state for the button
                  Combining triggers for popup and confirm modal cause some issues on hover / click
                */}
                {isContractOutdated ||
                !proContract.dateProSigned ||
                !!proContract.dateBilikSigned ||
                shouldWaitBilling ? (
                  <Popup
                    content={
                      <ul
                        style={{ padding: 0, paddingLeft: '16px', margin: 0 }}
                      >
                        {isContractOutdated && <li>{outdatedMessage}</li>}
                        {shouldWaitBilling && (
                          <li>
                            Vous devez attendre le prochain cycle de facturation
                            pour signer (le 5 du mois)
                          </li>
                        )}
                        {!proContract.dateProSigned && (
                          <li>Le pro n&apos;a pas encore signé le contrat</li>
                        )}
                        {proContract.dateBilikSigned && (
                          <li>Le contrat a déjà été signé par Bilik</li>
                        )}
                      </ul>
                    }
                    trigger={
                      <span>
                        <Button primary disabled>
                          {signBilikButtonContent}
                        </Button>
                      </span>
                    }
                  />
                ) : (
                  <ConfirmModal
                    header="Envoyer à Bilik pour signature"
                    content={
                      <p>
                        Après la signature, les abonnements seront
                        automatiquement créés. Continuer ?
                      </p>
                    }
                    onConfirm={async () => {
                      const response = await apiClient.post(
                        '/form/pro-view/send-contract-to-bilik',
                        {
                          proViewId,
                          documentKey: proContract.documentKey,
                        },
                      );
                      if (response.data?.success) {
                        ToastSuccess('Succès', 'Le contrat a bien été envoyé');
                      } else {
                        ToastError('Erreur', response.data?.message);
                      }
                      refetch();
                    }}
                    trigger={<Button primary>{signBilikButtonContent}</Button>}
                  />
                )}
              </>
            )}
          </Container>
        </>
      ) : null}
      {!proContract.configuration && (
        <Grid>
          <Grid.Column textAlign="center">
            <Button
              positive
              type="button"
              disabled={missingFields.length > 0}
              style={{ marginTop: '15px', width: '210px', marginBottom: 12 }}
              onClick={(): void => {
                setProContractFormModalState({
                  ...proContractFormModalState,
                  open: true,
                });
              }}
            >
              <Icon name="plus" />
              Générer le contrat
            </Button>
            {missingFields.length > 0 && (
              <p style={{ color: 'red' }}>
                Le contrat ne peut pas etre généré car il manque des
                informations sur la fiche : {missingFields.toString()}
              </p>
            )}
          </Grid.Column>
        </Grid>
      )}
      <ProContractFormModal
        {...proContractFormModalState}
        proOrganizationId={proOrganizationId}
        proViewId={proViewId}
        proPersonSuggestions={proPersonSuggestions}
        onProContractGenerated={(): void => {
          refetch();
        }}
        onClose={(): void => {
          setProContractFormModalState({
            ...proContractFormModalState,
            open: false,
          });
        }}
      />

      <DocumentPreviewModal
        open={previewModalState.open}
        onClose={(): void => {
          setPreviewModalState({ open: false });
        }}
        document={previewModalState.document}
      />

      <DocumentSendFormModal
        onClose={() => setDocumentSendFormModalState({ open: false })}
        onSubmitDocument={async (values) => {
          const response = await apiClient.post(
            '/form/pro-view/send-contract-to-pro',
            {
              proViewId,
              documentKey: proContract.documentKey,
              telephone: values.telephone,
              proReceiptEmail: values.proReceiptEmail,
            },
          );
          refetch();
          return response;
        }}
        proPersonSuggestions={proPersonSuggestions}
        receiptEmailSuggestions={receiptEmailSuggestions}
        open={documentSendFormModalState.open}
      />
    </>
  );
};

export default ProContract;
