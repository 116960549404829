import { apiClient } from 'axios-client';
import { Formik, useFormikContext } from 'formik';
import { ProFormArchiveModalQuery, ProViewSetInput } from 'generated/graphql';
import { ProFormUpdateFields } from 'pages/pros/pro-form/pro-form-update-fields-type';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { equals } from 'remeda';
import { ToastError, ToastSuccess } from 'utils';
import { flattenObject } from 'utils/flatten-object';
import { getFormErrorMessage } from 'utils/form-error-message';
import {
  ProFormArchiveFields,
  ProFormArchiveModalProps,
} from './pro-form-archive-modal-repository';
import ProFormArchiveModalView from './pro-form-archive-modal-view';
import * as Yup from 'yup';
import { useCurrentBilikPerson } from 'hooks/use-current-bilik-person/use-current-bilik-person';

type ProFormArchiveModalContainerProps = ProFormArchiveModalProps & {
  proViews?: NonNullable<
    ProFormArchiveModalQuery['proOrganization']
  >['proPresentations'][0]['proViews'];
  proViewId: number;
  proOrganizationId?: number;
  updateProView: (
    proViewIds: number[],
    set: ProViewSetInput,
  ) => Promise<number[] | undefined>;
  refetch: () => void;
};

const ProFormArchiveModalContainer: FunctionComponent<
  ProFormArchiveModalContainerProps
> = ({ proViewId, proViews, updateProView, onClose, refetch, ...rest }) => {
  const { setFieldValue, validateForm, handleSubmit } =
    useFormikContext<ProFormUpdateFields>();

  const { currentBilikPerson } = useCurrentBilikPerson();

  const handleCancelSchedule = (proViewId: number) => {
    updateProView([proViewId], {
      dateArchiveScheduled: null,
    }).catch((error) => {
      ToastError(
        'Erreur',
        `Impossible de déprogrammer de l'archivage de la fiche`,
      );
      throw error;
    });
    refetch();
    ToastSuccess('Succès', 'Archivage déprogrammé');
  };

  const onSubmit = useCallback(
    async (values: ProFormArchiveFields): Promise<void> => {
      const errors = await validateForm();

      const proViewIds = values.proViewIds;
      const dateArchiveScheduled = values.dateArchiveScheduled;

      if (equals(errors, {})) {
        if (dateArchiveScheduled) {
          await updateProView(proViewIds, {
            dateArchiveScheduled: dateArchiveScheduled,
          }).catch((error) => {
            ToastError(
              'Erreur',
              `Impossible de programmer l'archivage de la fiche`,
            );
            throw error;
          });
        } else {
          await apiClient
            .post(`/form/pro-view/archive`, {
              proViewIds,
              dateArchiveScheduled,
            })
            .catch((error) => {
              ToastError(
                'Erreur',
                "erreur lors de la notification de l'archivage aux particuliers",
              );
              throw error;
            });

          // Update the proView date archived to prevent the proView from being published again on handleSubmit
          setFieldValue('status', 'archived');
        }

        setTimeout(() => handleSubmit());
      } else {
        const errorValues = Object.values(flattenObject(errors)) as string[];
        const errorMsg = getFormErrorMessage(errorValues);

        ToastError(
          dateArchiveScheduled
            ? "Impossible de programmer l'archivage de la fiche"
            : "Impossible d'archiver la fiche",
          errorMsg,
        );
      }
    },
    [proViews, currentBilikPerson],
  );

  const initialValues: ProFormArchiveFields = useMemo(
    () => ({
      proViewIds: [proViewId],
      dateArchiveScheduled: undefined,
    }),
    [proViews],
  );

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        proViewIds: Yup.array().min(1, 'Fiche requise !'),
      })}
    >
      <ProFormArchiveModalView
        onClose={onClose}
        proViews={proViews}
        proViewId={proViewId}
        onCancelSchedule={handleCancelSchedule}
        {...rest}
      />
    </Formik>
  );
};

export default ProFormArchiveModalContainer;
