import FieldError from 'components/field-error/field-error';
import FormField from 'components/form-field/form-field';
import SelectTradeField from 'components/select-trade-field/select-trade-field';
import { useFormikContext } from 'formik';
import { useProViewRouteQueryLazyQuery } from 'generated/graphql';
import { ProFormUpdateFields } from 'pages/pros/pro-form/pro-form-update-fields-type';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FunctionComponent } from 'react';
import { useParams } from 'react-router';
import { isNumber } from 'remeda';
import {
  Button,
  Divider,
  DropdownItemProps,
  Form,
  Icon,
  Label as LabelUi,
  Modal,
} from 'semantic-ui-react';

type TradeFieldsProps = {
  bilikZoneId: number;
  action: 'create-pro' | 'update-pro' | 'add-zone';
};

const TradeFields: FunctionComponent<TradeFieldsProps> = ({
  bilikZoneId,
  action,
}) => {
  const proViewId = Number(useParams<{ proViewId: string }>().proViewId);

  const { values, setFieldTouched, setFieldValue, errors } =
    useFormikContext<ProFormUpdateFields>();

  const [getOtherProViews, { data }] = useProViewRouteQueryLazyQuery({
    variables: {
      proViewId,
    },
  });

  const getOtherProViewsData = useCallback(() => {
    getOtherProViews();
  }, [getOtherProViews]);

  const proPresentationProViewsBilikZone = useMemo(
    () =>
      data?.proViewByPk?.proPresentation.proViews.map(
        (proView) => proView.bilikZone.mainCityCode,
      ),
    [data],
  );

  const [selectMainTradeOptions, setSelectMainTradeOptions] = useState<
    DropdownItemProps[]
  >([]);

  const [updateMainTradeModalOpen, setUpdateMainTradeModalOpen] =
    useState<boolean>(false);

  const [temporaryMainTradeId, setTemporaryMainTradeId] = useState<
    number | null
  >(values.proPresentation.mainTradeId);

  const [temporarySecondaryTradeId, setTemporarySecondaryTradeId] = useState<
    number | null
  >(values.proPresentation.secondaryTradeId);

  const [isBis, setIsBis] = useState<boolean>(false);

  return (
    <>
      <SelectTradeField
        key={`select-trade-field-${values.proPresentation.mainTradeId}-${values.proPresentation.secondaryTradeId}`}
        label="Catégories"
        name="trades"
        bilikZoneId={bilikZoneId}
        multiple
        selection
        search
        required
        onOptionsLoad={(options): void => {
          setSelectMainTradeOptions(
            options.filter((option) =>
              values.trades.includes(Number(option.key)),
            ),
          );
        }}
        renderLabel={
          // We can't use this renderLabel function when we create a pro
          // because we don't have the trades in the formik values yet
          action !== 'create-pro'
            ? (item: DropdownItemProps) => {
                return (
                  <LabelUi
                    color={
                      item.value == values.proPresentation.mainTradeId ||
                      item.value == values.proPresentation.secondaryTradeId
                        ? 'blue'
                        : undefined
                    }
                    basic={
                      item.value == values.proPresentation.secondaryTradeId
                    }
                    content={item.text}
                    removeIcon={
                      item.value == values.proPresentation.mainTradeId ||
                      (item.value == values.proPresentation.secondaryTradeId &&
                        action === 'add-zone')
                        ? null
                        : 'delete'
                    }
                    onRemove={(_, data) => {
                      setFieldTouched('trades', true);
                      setFieldValue(
                        'trades',
                        values.trades.filter((trade) => trade !== data.value),
                      );

                      if (
                        data.value === values.proPresentation.secondaryTradeId
                      ) {
                        setFieldTouched(
                          'proPresentation.secondaryTradeId',
                          true,
                        );
                        setFieldValue('proPresentation.secondaryTradeId', null);
                      }
                    }}
                  />
                );
              }
            : undefined
        }
        onChange={async (_, { value, options }): Promise<void> => {
          const newValues = value as number[];

          if (
            !values.proPresentation.mainTradeId ||
            (values.proPresentation.mainTradeId &&
              !newValues.includes(values.proPresentation.mainTradeId))
          ) {
            setFieldTouched('proPresentation.mainTradeId', true);
            setFieldValue('proPresentation.mainTradeId', null);
          }

          setFieldTouched('trades', true);
          setFieldValue('trades', newValues);

          if (options) {
            setSelectMainTradeOptions(
              options.filter((option) => newValues.includes(option.key)),
            );
          }
        }}
      />
      <Form.Group widths="equal">
        <FormField
          search
          required
          label="Catégorie principale"
          type="select"
          selection
          name="proPresentation.mainTradeId"
          placeholder="Sélectionnez une catégorie..."
          options={selectMainTradeOptions}
          onChange={
            action !== 'create-pro'
              ? (_, { value }): void => {
                  // If not a number (empty string or null), set to null
                  const isNum = isNumber(value);

                  setTemporaryMainTradeId(isNum ? value : null);
                  getOtherProViewsData();
                  setUpdateMainTradeModalOpen(true);
                  setIsBis(false);
                }
              : undefined
          }
          disabled={action === 'add-zone'}
        />
        {action !== 'create-pro' ? (
          <FormField
            search
            label="Catégorie principale Bis"
            type="select"
            selection
            name="proPresentation.secondaryTradeId"
            clearable
            placeholder="Sélectionnez une catégorie..."
            // Remove mainTrade from options
            options={selectMainTradeOptions.filter(
              (option) => values.proPresentation.mainTradeId !== option.key,
            )}
            selectOnBlur={false}
            onChange={(_, { value }): void => {
              // If not a number (empty string or null), set to null
              const isNum = isNumber(value);
              setTemporarySecondaryTradeId(isNum ? value : null);
              getOtherProViewsData();
              setUpdateMainTradeModalOpen(true);
              setIsBis(true);
            }}
            disabled={action === 'add-zone'}
          />
        ) : null}
      </Form.Group>
      {errors['proPresentation']?.['secondaryTradeId'] ? (
        <>
          <FieldError color="red">
            Cette association de métiers principal/bis n&apos;est pas autorisée.
            Tu peux consulter{' '}
            <a href="/trades_guide" target="_blank" rel="noopener noreferrer">
              les associations de catégories spécifiques au guide{' '}
            </a>{' '}
            (métiers split) pour voir les associations autorisées.
          </FieldError>
          <Divider hidden />
        </>
      ) : null}
      <Modal open={updateMainTradeModalOpen} size="tiny">
        <Modal.Header>
          <Icon name="warning sign" color="red" />
          Attention
        </Modal.Header>
        <Modal.Content>
          <p>
            Pensez à <b>enregistrer</b> pour mettre à jour la catégorie
            principale {isBis && 'BIS'}.{' '}
            {temporaryMainTradeId ===
              values.proPresentation.secondaryTradeId && (
              <>
                <br />
                <br />
                <b>
                  Pour info, la catégorie sera enlevée de la catégorie
                  principale BIS{' '}
                </b>
                <br />
              </>
            )}
            {proPresentationProViewsBilikZone &&
            proPresentationProViewsBilikZone?.length > 1 ? (
              <>
                <b>
                  Pour info, la catégorie principale {isBis && 'BIS'} sera
                  egalement modifiée dans les zones{' '}
                </b>
                :
                <ul>
                  {proPresentationProViewsBilikZone?.map((mainCityCode) => (
                    <li key={mainCityCode}>{mainCityCode}</li>
                  ))}
                </ul>
              </>
            ) : null}
          </p>

          <p>Êtes-vous sûr de vouloir continuer ?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={(): void => {
              setUpdateMainTradeModalOpen(false);
            }}
          >
            Annuler
          </Button>
          <Button
            primary
            onClick={(): void => {
              if (isBis) {
                setFieldTouched('proPresentation.secondaryTradeId', true);
                setFieldValue(
                  'proPresentation.secondaryTradeId',
                  temporarySecondaryTradeId,
                );
              } else {
                setFieldValue(
                  'proPresentation.mainTradeId',
                  temporaryMainTradeId,
                );
                if (
                  temporaryMainTradeId ===
                  values.proPresentation.secondaryTradeId
                ) {
                  setFieldValue('proPresentation.secondaryTradeId', null);
                }
              }
              setUpdateMainTradeModalOpen(false);
            }}
          >
            Confirmer
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default TradeFields;
