import { Formik } from 'formik';
import {
  SolicitationsTableQuery,
  usePiBouncedSolicitationUpdateMutation,
} from 'generated/graphql';
import React, { FunctionComponent, useState } from 'react';
import { Button, Icon, Label, Modal } from 'semantic-ui-react';
import { ToastError, ToastSuccess } from 'utils';
import { Unpacked } from 'utils/unpacked';
import * as Yup from 'yup';
import { PiBouncedSolicitationFormView } from './pi-bounced-solicitation-form-view';

type PiBouncedSolicitationRepositoryProps = {
  solicitation: Unpacked<SolicitationsTableQuery['solicitation']>;
  resendMailToPro: (uuid: string) => Promise<void>;
};

export const PiBouncedSolicitation: FunctionComponent<
  PiBouncedSolicitationRepositoryProps
> = ({ solicitation, resendMailToPro }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [updateSolicitationByPk] = usePiBouncedSolicitationUpdateMutation();

  if (!solicitation.proView.emailTarget) {
    return <>error</>;
  }

  return (
    <>
      <Label as="a" onClick={(): void => setIsModalOpen(true)} color="red">
        <>
          <Icon name="exclamation triangle" />
          Important : Cliquez-ici ! (Erreur dans l&apos;email du particulier)
        </>
      </Label>
      <Modal
        size="tiny"
        open={isModalOpen}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Action à faire</Modal.Header>
        <Modal.Content>
          <p>
            Le particulier a renseigné une adresse mail invalide. Vous pouvez la
            modifier ci-dessous puis renvoyer la demande au pro.
          </p>

          <Formik
            initialValues={{
              email: solicitation.email,
            }}
            onSubmit={async (values): Promise<void> => {
              try {
                await updateSolicitationByPk({
                  variables: {
                    solicitationId: solicitation.id,
                    solicitation: {
                      email: values.email,
                    },
                  },
                });

                ToastSuccess('Succès', 'Email du particulier mis à jour');

                if (solicitation.uuid) {
                  await resendMailToPro(solicitation.uuid).then(() =>
                    setIsModalOpen(false),
                  );
                }
              } catch (e) {
                ToastError(
                  'Erreur',
                  'Une erreur est survenue lors de la mise à jour des coordonnées géographiques',
                );
              }
            }}
            onReset={(): void => {
              setIsModalOpen(false);
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email().required('adresse mail requise'),
            })}
          >
            <PiBouncedSolicitationFormView />
          </Formik>
        </Modal.Content>
        <Modal.Actions>
          <Button
            type="button"
            icon="remove"
            content="Fermer"
            onClick={(): void => setIsModalOpen(false)}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};
