import React, { FunctionComponent, useCallback, useEffect } from 'react';
import ProContactsGraphsView from './pro-contacts-graphs-view';
import { Serie } from '@nivo/line';
import { CountByMonth } from '../utils/count-by-month.type';
import { convertTableData } from '../utils/convert-table-data';
import { format } from 'date-fns';

type ProContactsGraphsContainerProps = {
  getYearData: (year: number, proViewIds?: number[]) => Promise<CountByMonth[]>;
  year: number;
  proViewCountData?: CountByMonth[];
  compareYear: number;
  proViewIds?: number[];
};

const ProContactsGraphsContainer: FunctionComponent<
  ProContactsGraphsContainerProps
> = ({ getYearData, year, compareYear, proViewIds, proViewCountData }) => {
  const [newContactChartData, setNewContactChartData] = React.useState<
    Serie[] | null
  >(null);

  const [proPublishedChartData, setProPublishedChartData] = React.useState<
    Serie[] | null
  >(null);

  const [avgNewContactByProPublished, setAvgNewContactByProPublished] =
    React.useState<Serie[] | null>(null);

  const fetchNewContactsData = useCallback(async () => {
    const data: CountByMonth[] = [];

    if (compareYear !== year) {
      const compareYearData = await getYearData(compareYear, proViewIds);

      data.push(...compareYearData);
    }

    const yearData = await getYearData(year, proViewIds);

    data.push(...yearData);

    return data;
  }, [proViewIds, year, compareYear]);

  useEffect(() => {
    if (!proViewCountData?.length) {
      return;
    }

    // Reset data when years comparison changes to set loading
    setNewContactChartData(null);
    setProPublishedChartData(null);
    setAvgNewContactByProPublished(null);

    fetchNewContactsData().then((newContactData) => {
      // Set new contact chart data

      setNewContactChartData(
        convertTableData(newContactData, [year, compareYear]),
      );

      setProPublishedChartData(
        convertTableData(proViewCountData, [year, compareYear]),
      );

      setAvgNewContactByProPublished(
        convertTableData(
          proViewCountData.map((data) => {
            const avg = newContactData
              ? newContactData.find(
                  (newContact) =>
                    String(newContact.month) === format(data.month, 'yyyy-MM'),
                )?.count ?? 0
              : 0;

            return {
              month: data.month,
              count:
                data.count !== 0
                  ? Math.round((avg / data.count) * 1000) / 1000
                  : 0,
            };
          }),
          [year, compareYear],
        ),
      );
    });
  }, [fetchNewContactsData, proViewCountData, year, compareYear]);

  return (
    <ProContactsGraphsView
      newContactChartdata={newContactChartData}
      avgNewContactByProPublished={avgNewContactByProPublished}
      proPublishedChartData={proPublishedChartData}
    />
  );
};

export default ProContactsGraphsContainer;
