import { useMutation } from '@tanstack/react-query';
import { Formik } from 'formik';
import { authCreateUserMutation } from 'generated/api-client/@tanstack/react-query.gen';
import React, { FunctionComponent, ReactNode, useCallback } from 'react';
import { ToastError, ToastSuccess } from 'utils';
import { AuthUserFormFields } from './auth-user-form-field.type';
import accountFormValidationSchema from './auth-user-form-validation-schema';

interface AuthUserFormCreateProps {
  children?: ReactNode;
  onUserCreated?: (userId: string) => void;
}

const AuthUserFormCreate: FunctionComponent<AuthUserFormCreateProps> = ({
  onUserCreated,
  children,
}) => {
  const { mutateAsync: createAuthUser } = useMutation(authCreateUserMutation());

  const onSubmit = useCallback(
    async (values: AuthUserFormFields) => {
      createAuthUser({
        body: { email: values.email, groups: ['/pro-people/pros'] },
      })
        .then((id) => {
          ToastSuccess('Succès', 'Adresse email créée');
          onUserCreated?.(id);
        })
        .catch(() => {
          ToastError(
            'Erreur',
            "Une erreur est survenue lors de la création de l'adresse email",
          );
        });
    },
    [createAuthUser],
  );

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ email: '' }}
      validationSchema={accountFormValidationSchema()}
    >
      {children}
    </Formik>
  );
};

export default AuthUserFormCreate;
