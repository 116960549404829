import FormField from 'components/form-field/form-field';
import { useFormikContext } from 'formik';
import React, { FunctionComponent } from 'react';
import { Form } from 'semantic-ui-react';
import { AuthUserFormFields } from './auth-user-form-field.type';

const AuthUserFormView: FunctionComponent = () => {
  const { handleSubmit, handleReset, setFieldValue } =
    useFormikContext<AuthUserFormFields>();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    // lowercase email for validation
    if (name === 'email') {
      setFieldValue(name, value.toLowerCase());
    }
  };

  return (
    <Form onSubmit={handleSubmit} onReset={handleReset}>
      <FormField
        type="email"
        label="email"
        name="email"
        onChange={onChange}
        required
      />
    </Form>
  );
};

export default AuthUserFormView;
