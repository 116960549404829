import { useQuery } from '@tanstack/react-query';
import { authFindUsersOptions } from 'generated/api-client/@tanstack/react-query.gen';
import {
  useProViewProPersonDeleteConfirmModalQuery,
  useProViewProPersonDeleteConfirmModalQueryLazyQuery,
} from 'generated/graphql';
import React, { FunctionComponent, useEffect } from 'react';
import ProViewProPersonDeleteConfirmModalContainer from './pro-view-pro-person-delete-confirm-modal-container';
import { ProViewProPersonDeleteConfirmModalProps } from './pro-view-pro-person-delete-confirm-modal-view';

type ProViewProPersonDeleteConfirmModalRepositoryProps =
  ProViewProPersonDeleteConfirmModalProps & {
    currentProViewId?: number;
    proPersonId: number;
    proPersonEmail?: string;
    onConfirm?: (proPersonId: number) => void;
  };

const ProViewProPersonDeleteConfirmModalRepository: FunctionComponent<
  ProViewProPersonDeleteConfirmModalRepositoryProps
> = ({
  proPersonId,
  proPersonEmail,
  currentProViewId,
  onConfirm,
  onClose,
  ...rest
}) => {
  const { data: authUsers, isLoading: authUsersLoading } = useQuery(
    authFindUsersOptions({
      query: { email: proPersonEmail, exact: true, limit: 1 },
    }),
  );

  const [getData, { data, loading }] =
    useProViewProPersonDeleteConfirmModalQueryLazyQuery();

  useEffect(() => {
    if (!authUsers || authUsers.length === 0) return;
    getData({
      variables: {
        proPersonId,
        authUserId: authUsers[0].id,
      },
    });
  }, [authUsers]);

  return (
    <ProViewProPersonDeleteConfirmModalContainer
      data={data}
      loading={loading || authUsersLoading}
      currentProViewId={currentProViewId}
      proPersonId={proPersonId}
      onClose={onClose}
      onConfirm={onConfirm}
      {...rest}
    />
  );
};
export default ProViewProPersonDeleteConfirmModalRepository;
