import {
  Button,
  Container,
  Form,
  Header,
  Icon,
  Segment,
} from 'semantic-ui-react';
import { Field, useFormikContext } from 'formik';
import React, { FunctionComponent } from 'react';

import AvatarUploadContainer from 'components/avatar-upload/avatar-upload';
import Label from 'components/label/label';
import FormField from 'components/form-field/form-field';
import { BilikPersonFormFields } from './bilik-person-form-fields.type';
import { fr } from 'date-fns/locale';

type BilikPeopleFormViewProps = {
  action: 'create' | 'update';
};

const BilikPersonFormView: FunctionComponent<BilikPeopleFormViewProps> = ({
  action,
}) => {
  const { values, setFieldValue, handleReset, handleSubmit, isSubmitting } =
    useFormikContext<BilikPersonFormFields>();

  return (
    <Form onReset={handleReset} onSubmit={handleSubmit}>
      <Segment.Group>
        <Segment color="blue">
          <Header>Général</Header>
        </Segment>
        <Segment>
          <Label>Avatar</Label>
          <div
            style={{
              width: '225px',
              height: '225px',
              marginBottom: '14px',
            }}
          >
            <Field
              id="avatarHash"
              name="avatarHash"
              component={AvatarUploadContainer}
              value={values.avatarHash}
              onChange={(event, { value }): void => {
                setFieldValue('avatarHash', value);
              }}
            />
          </div>
          <FormField type="text" label="Prénom" name="givenName" required />
          <FormField type="text" label="Nom" name="familyName" required />
          <FormField
            type="telephone"
            placeholder="Ex: +33612345678"
            label="Téléphone"
            name="telephone"
            required
          />
          <FormField
            label="Rôle"
            type="select"
            selection
            name="roleName"
            placeholder="Selectionnez un rôle..."
            options={[
              { key: 'user', value: 'user', text: 'Utilisateur' },
              { key: 'admin', value: 'admin', text: 'Administrateur' },
            ]}
            required
          />
          <FormField
            type="date"
            label="Date de d'embauche"
            name="employmentDate"
            values={values}
            dateFormat="dd/MM/yyyy"
            locale={fr}
            required
          />
          <FormField
            type="select"
            label="Statut"
            selection
            name="status"
            placeholder="Selectionnez un statut..."
            options={[
              { key: 'published', value: 'published', text: 'Publié' },
              { key: 'archived', value: 'archived', text: 'Archivé' },
            ]}
            required
          />
          <FormField type="text" label="Description" name="description" />
        </Segment>
      </Segment.Group>
      <Segment.Group>
        <Segment color="blue">
          <Header>Compte</Header>
        </Segment>
        <Segment>
          <FormField
            type="email"
            placeholder="Ex: admin@bilik.fr"
            label="Email"
            helpText="Nom d'utilisateur"
            name="email"
            required
          />
          <FormField
            type="password"
            helpText={
              action === 'update'
                ? 'Laisser vide pour garder le mot de passe actuel'
                : undefined
            }
            label={
              action === 'update' ? 'Nouveau mot de passe' : 'Mot de passe'
            }
            name="newPassword"
            required={action !== 'update'}
          />
        </Segment>
      </Segment.Group>
      <Container fluid textAlign="right" style={{ marginTop: '15px' }}>
        <Button primary disabled={isSubmitting} loading={isSubmitting}>
          <Icon name="save" />
          Enregistrer
        </Button>
      </Container>
    </Form>
  );
};

export default BilikPersonFormView;
