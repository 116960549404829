import {
  Button,
  Container,
  Form,
  Header,
  Icon,
  Segment,
  Transition,
  FormField as FormFieldUi,
} from 'semantic-ui-react';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { BilikZoneFormFields } from './bilik-zone-form-fields.type';
import FormField from 'components/form-field/form-field';
import { useFormikContext } from 'formik';
import SelectBilikPersonField from 'components/select-bilik-person-field/select-bilik-person-field';
import SelectZohoOrganizationField from 'components/select-zoho-organization-field/select-zoho-organization-field';
import { excludeColor } from 'utils/geometry';
import { LatLng } from 'leaflet';
import SelectCityField from 'components/select-city-field/select-city-field';
import { InterventionsField } from 'components/interventions-field';
import { centroid } from '@turf/turf';
import { useBilikZoneFormGetInterventionsFieldDataQuery } from 'generated/graphql';
import { useIsUserAdmin } from 'hooks/use-is-user-admin/use-is-user-admin';

const BilikZoneFormView: FunctionComponent<{ action: 'create' | 'update' }> = ({
  action,
}) => {
  const isCurrentUserAdmin = useIsUserAdmin();

  const {
    values,
    handleReset,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    errors,
  } = useFormikContext<BilikZoneFormFields>();
  console.log({ errors });

  const { data } = useBilikZoneFormGetInterventionsFieldDataQuery({
    variables: {
      currentBilikZoneId: values.id ?? 0,
    },
  });

  const [callTrackingTargetValue, setCallTrackingTargetValue] =
    useState<string>();

  const [genericEmailValue, setGenericEmailValue] = useState<string>();

  const defaultMapCenter: LatLng | null = useMemo(() => {
    const area = data?.currentBilikZones?.area;
    if (!area) return null;
    const center = centroid(area).geometry.coordinates;
    return new LatLng(center[1], center[0]);
  }, [data?.currentBilikZones]);

  return (
    <Form onReset={handleReset} onSubmit={handleSubmit}>
      <Segment.Group>
        <Segment color="blue">
          <Header>Général</Header>
        </Segment>
        <Segment>
          <FormField
            type="text"
            placeholder="Ex: Grenoble et Agglo"
            label="Nom"
            name="name"
            required
            disabled={!isCurrentUserAdmin}
          />
          <FormField
            type="text"
            placeholder="Ex: grenoble"
            label="Slug"
            helpText="Le slug est la version normalisée du nom. Il ne contient généralement que des lettres minuscules non accentuées, des chiffres et des traits d’union."
            name="slug"
            required
            disabled={!isCurrentUserAdmin}
          />
          <SelectBilikPersonField
            name="managers"
            label="Responsable(s) de la zone"
            required
            multiple
            search
            selection
            filters={{
              exceptIds: values.members.length > 0 ? values.members : undefined,
            }}
            disabled={!isCurrentUserAdmin}
          />
          <SelectBilikPersonField
            name="members"
            label="Membres"
            multiple
            search
            selection
            clearable
            filters={{
              exceptIds:
                values.managers.length > 0 ? values.managers : undefined,
            }}
            disabled={!isCurrentUserAdmin}
          />
          <FormField
            type="email"
            placeholder="Ex: grenoble@bilik.fr"
            label="Email générique"
            name="defaultGenericEmail"
            required
            disabled={!isCurrentUserAdmin}
          />
        </Segment>
      </Segment.Group>

      {isCurrentUserAdmin && (
        <Segment.Group>
          <Segment color="blue">
            <Header>Carte</Header>
          </Segment>
          <Segment>
            {data?.otherBilikZones && (
              <InterventionsField
                defaultMapCenter={defaultMapCenter}
                name="cities"
                interventionTypes={['include']}
                otherFeatures={{
                  type: 'FeatureCollection',
                  features:
                    data?.otherBilikZones.map((bilikZone) => ({
                      type: 'Feature',
                      properties: {
                        color: excludeColor,
                        fill: excludeColor,
                        stroke: false,
                        fillColor: excludeColor,
                        fillOpacity: 0.2,
                      },
                      geometry: bilikZone.area ?? {
                        type: 'MultiPolygon',
                        coordinates: [],
                      },
                    })) ?? [],
                }}
              />
            )}
          </Segment>
        </Segment.Group>
      )}
      <Segment.Group>
        <Segment color="blue">
          <Header>Call tracking</Header>
        </Segment>
        <Segment>
          <FormField
            label="Numéro call traking fixe"
            type="telephone"
            name="telephone"
            placeholder="Ex: 0451245874"
            required
            disabled={!isCurrentUserAdmin}
          />
          <Container fluid>
            <Form.Group widths="equal">
              <FormField
                label="Numéro de téléphone cible"
                type="telephone"
                name="defaultCallTrackingTarget.telephone"
                placeholder="Ex: 06 44 64 27 74"
                disabled={!isCurrentUserAdmin}
                required
              />
              <FormField
                type="text"
                placeholder="Ex: Nom"
                label="Libéllé"
                name="defaultCallTrackingTarget.name"
                required
                disabled={!isCurrentUserAdmin}
              />
            </Form.Group>
          </Container>
          <FormField
            type="checkbox"
            name="shouldDisplayContactInPiSms"
            label="Afficher le téléphone de la zone dans le SMS envoyé aux particuliers après un appel"
          />
        </Segment>
      </Segment.Group>
      {action === 'update' ? (
        <Segment.Group>
          <Segment color="blue">
            <Header>Relai</Header>
          </Segment>
          <Segment>
            <FormField
              type="checkbox"
              label="Activer le relai des emails"
              name="isEmailRelayActive"
              onChange={(e) => {
                const checked = e.target.checked;

                if (checked) {
                  setFieldValue('genericEmail', undefined);
                }
              }}
            />
            {values.isEmailRelayActive ? (
              <>
                <SelectBilikPersonField
                  name="genericEmail"
                  label="Utilisateur relai"
                  helpText={`Sélectionnez l'utilisateur qui va prendre le relai des emails. Utilisez l'option "Personnalisé" si vous souhaitez renseigner un email spécifique.`}
                  additionalOptions={[
                    {
                      key: `generic-email-options-custom`,
                      text: <>Personnalisé</>,
                      value: 'custom',
                    },
                  ]}
                  valueType="relayEmail"
                  filters={{
                    status: 'published',
                  }}
                  onChange={(e, { value }) => {
                    if (value) {
                      setFieldValue(
                        'genericEmail',
                        value === 'custom' ? undefined : value,
                      );

                      setGenericEmailValue(String(value));
                    }
                  }}
                  onBilikPeopleLoaded={(bilikPeople) => {
                    if (values.isEmailRelayActive) {
                      const genericEmailSelected = bilikPeople.find(
                        (option) => option.relayEmail === values.genericEmail,
                      );

                      setGenericEmailValue(
                        genericEmailSelected // ProPerson option
                          ? genericEmailSelected.relayEmail
                          : values.genericEmail
                          ? 'custom' // Custom option
                          : undefined, // Never selected (create case));
                      );
                    }
                  }}
                  customValue={genericEmailValue}
                  clearable
                  selection
                  required
                />
                <Transition.Group duration={250}>
                  {genericEmailValue === 'custom' ? (
                    <Container
                      fluid
                      style={{ paddingLeft: '50px', paddingBottom: '15px' }}
                    >
                      <FormField
                        type="email"
                        placeholder="Ex : paul+relai@bilik.fr"
                        label="Email relai personnalisé"
                        name="genericEmail"
                        required
                      />
                    </Container>
                  ) : null}
                </Transition.Group>
              </>
            ) : null}

            <FormField
              type="checkbox"
              label="Activer le relai des appels téléphoniques"
              name="isTelephoneRelayActive"
              onChange={(e) => {
                const checked = e.target.checked;

                if (checked) {
                  setFieldValue('callTrackingTarget', undefined);
                }
              }}
            />
            {values.isTelephoneRelayActive ? (
              <>
                <SelectBilikPersonField
                  name="callTrackingTarget"
                  label="Utilisateur relai"
                  helpText={`Sélectionnez l'utilisateur qui va prendre le relai des appels téléphoniques. Utilisez l'option "Personnalisé" si vous souhaitez renseigner un email spécifique.`}
                  filters={{
                    status: 'published',
                  }}
                  additionalOptions={[
                    {
                      key: `call-tracking-target-options-custom`,
                      text: <>Personnalisé</>,
                      value: 'custom',
                    },
                  ]}
                  valueType="telephone"
                  onChange={(e, { value }) => {
                    if (value) {
                      setTimeout(() => {
                        setFieldTouched('callTrackingTarget.telephone');
                      }, 0);
                      setFieldValue(
                        'callTrackingTarget.telephone',
                        value === 'custom' ? undefined : value,
                      );
                      setFieldValue(
                        'callTrackingTarget.name',
                        value === 'custom' ? undefined : 'relay',
                      );

                      setCallTrackingTargetValue(String(value));
                    }
                  }}
                  onBilikPeopleLoaded={(bilikPeople) => {
                    if (values.isTelephoneRelayActive) {
                      const callTrackingTargetSelected = bilikPeople.find(
                        (option) =>
                          option?.telephone ===
                          values.callTrackingTarget?.telephone,
                      );

                      setCallTrackingTargetValue(
                        callTrackingTargetSelected // ProPerson option
                          ? callTrackingTargetSelected.telephone
                          : values.callTrackingTarget?.telephone
                          ? 'custom' // Custom option
                          : undefined, // Never selected (create case));
                      );
                    }
                  }}
                  customValue={callTrackingTargetValue}
                  clearable
                  selection
                  required
                />
                <Transition.Group duration={250}>
                  {callTrackingTargetValue === 'custom' ? (
                    <Container
                      fluid
                      style={{ paddingLeft: '50px', paddingBottom: '15px' }}
                    >
                      <Form.Group widths="equal">
                        <FormField
                          label="Numéro de téléphone relai"
                          type="telephone"
                          name="callTrackingTarget.telephone"
                          placeholder="Ex: 06 44 64 27 74"
                          required
                        />
                        <FormField
                          type="text"
                          placeholder="Ex: Nom"
                          label="Libéllé"
                          name="callTrackingTarget.name"
                          required
                        />
                      </Form.Group>
                    </Container>
                  ) : null}
                </Transition.Group>
              </>
            ) : null}
          </Segment>
        </Segment.Group>
      ) : null}
      <Segment.Group>
        <Segment color="blue">
          <Header>Ville</Header>
        </Segment>
        <Segment>
          <SelectCityField
            label="Ville principale"
            helpText="Sélectionnez la ville principale parmis les villes attachées à la bilikzone"
            name="mainCityId"
            cityIds={values?.cities.map((city) => city.id) ?? []}
            search
            selection
            disabled={!isCurrentUserAdmin}
          />
          <FormField
            type="text"
            placeholder="Ex: GRE"
            label="Code ville principale"
            helpText="3 caractères, en lettres majuscules"
            name="mainCityCode"
            required
            disabled={!isCurrentUserAdmin}
          />
          <FormField
            type="text"
            placeholder="Ex: Grenoble & environs"
            label="Nom de la bilikZone dans le guide"
            name="guideName"
            required
            disabled={!isCurrentUserAdmin}
          />
          <FormField
            type="text"
            placeholder="Ex: 38"
            label="Code département"
            name="regionPostalCode"
            required
            disabled={!isCurrentUserAdmin}
          />
          <FormField
            type="checkbox"
            label="Possède des arrondissements ?"
            name="hasDistrict"
            disabled={!isCurrentUserAdmin}
          />
          <FormField
            type="checkbox"
            label="Possède plusieurs codes postaux ?"
            name="hasMultiplePostalCode"
            disabled={!isCurrentUserAdmin}
          />
        </Segment>
      </Segment.Group>
      <Segment.Group>
        <Segment color="blue">
          <Header>Adresse</Header>
        </Segment>
        <Segment>
          <FormField
            type="text"
            placeholder="Ex: 15 rue Pierre Dupont"
            label="Adresse"
            name="streetAddress"
            required
            disabled={!isCurrentUserAdmin}
          />
          <FormField
            type="text"
            placeholder="Ex: 38000"
            label="Code postal"
            name="postalCode"
            required
            disabled={!isCurrentUserAdmin}
          />
          <FormField
            type="text"
            placeholder="Ex: Grenoble"
            label="Ville"
            name="addressLocality"
            required
            disabled={!isCurrentUserAdmin}
          />
          <FormField
            type="text"
            placeholder="Ex: BP 12345"
            label="Boite postale"
            name="postOfficeBoxNumber"
            disabled={!isCurrentUserAdmin}
          />
        </Segment>
      </Segment.Group>
      <Segment.Group>
        <Segment color="blue">
          <Header>Zoho</Header>
        </Segment>
        <Segment>
          <SelectZohoOrganizationField
            label="Zoho Organization"
            helpText="Il faut regarder à quel entreprise la zone est rattachée dans Zoho"
            name="zohoOrganizationId"
            selection
            search
            clearable
            disabled={!isCurrentUserAdmin}
          />
        </Segment>
      </Segment.Group>

      <Container fluid textAlign="right" style={{ marginTop: '15px' }}>
        <Button
          primary
          type="submit"
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          <Icon name="save" />
          Enregistrer
        </Button>
      </Container>
    </Form>
  );
};

export default BilikZoneFormView;
