import { utcToZonedTime } from 'date-fns-tz';
import {
  TimelinePrivateIndividualQuery,
  useGetBilikPersonByContactInfoQuery,
  useGetBilikPersonByContactInfoQueryLazyQuery,
} from 'generated/graphql';
import { format } from 'date-fns';
import { groupBy, uniq, concat } from 'remeda';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { Loader } from 'semantic-ui-react';
import {
  DelimitedArrayParam,
  StringParam,
  useQueryParams,
} from 'use-query-params';
import { TimelineData } from './timeline-repository';
import { TimelineView } from './timeline-view';
import locale from 'date-fns/locale/fr';
import { MongoEvent } from 'interfaces/events.interface';
import { ApolloQueryResult } from '@apollo/client';

type TimelineContainerProps = {
  searchValue: string;
  fetchEvents: (
    filters: any,
    contactInfos: ContactInfos,
  ) => Promise<MongoEvent[]>;
  fetchPrivateIndividuals: (
    searchValue: string,
  ) => Promise<ApolloQueryResult<TimelinePrivateIndividualQuery>>;
};

export type ContactInfos = {
  emails: string[];
  telephones: string[];
  addressLocalities: string[];
  names: string[];
};

const fakeNumbers = [
  '+33600000000',
  '+33612345678',
  '+33700000000',
  '+33712345678',
  '+33606060606',
  '+33707070707',
  '+33102030405',
  '+33123456789',
  'null',
  'NULL',
];

const isNullStringValue = (field: string | undefined): boolean => {
  return field !== 'null' && field !== 'NULL';
};

const setDefaultSearchValueType = (
  searchValue: string,
  contactInfos: ContactInfos,
): void => {
  if (searchValue.charAt(0) == '+') {
    contactInfos.telephones.push(searchValue);
  } else if (!contactInfos.emails.length) {
    contactInfos.emails.push(searchValue);
  }
};

const ignoredEventsType = [
  'SmsStatusUpdated',
  'EmailBounceReceived',
  'EmailOpenedReceived',
  'EmailDeliveryReceived',
  'EmailClickReceived',
];

const filterIgnoredEvents = (events: any): MongoEvent[] => {
  return events.filter((event: MongoEvent) => {
    // When fetching all event types deny unused types
    if (ignoredEventsType.includes(event.type)) {
      return false;
    }

    return true;
  });
};

const getContactedPros = async (
  contactInfos: ContactInfos,
  fetchEvents: TimelineContainerProps['fetchEvents'],
): Promise<string[]> => {
  const piEvents = await fetchEvents(null, contactInfos);

  const contactProByPiEvents: MongoEvent[] = piEvents.filter(
    (event) =>
      event.type === 'CallDenied' ||
      event.type === 'CallStatusUpdated' ||
      event.type === 'FormCreateSolicitation' ||
      event.type === 'SmsReceived' ||
      event.type === 'FormCreateProReview',
  );

  // List of all proPresentationName contacted by the pi
  const pros: string[] = [];

  contactProByPiEvents.forEach((event) => {
    const eventProName =
      event.actors?.pro?.proPresentation?.name ||
      event.initiator?.pro?.proPresentation?.name;

    if (eventProName && !pros.includes(eventProName)) {
      pros.push(eventProName);
    }
  });

  const sortedPros = pros.sort((a, b) => a.localeCompare(b));

  return sortedPros;
};

export const TimelineContainer: FunctionComponent<TimelineContainerProps> = ({
  searchValue,
  fetchEvents,
  fetchPrivateIndividuals,
}) => {
  const [filters, setFilters] = useQueryParams({
    email: StringParam,
    telephone: StringParam,
    proPresentationName: StringParam,
    eventTypes: DelimitedArrayParam,
  });
  const [timelineData, setTimelineData] = useState<TimelineData>();
  const [refetchEvents, setRefetchEvents] = useState<boolean>(false);
  const [contactInfos, setContactInfos] = useState<ContactInfos>();
  const [contactedPros, setContactedPros] = useState<string[]>([]);

  const [getBilikPerson] = useGetBilikPersonByContactInfoQueryLazyQuery();

  useEffect(() => {
    const findRelatedContactInfos = async (
      searchValue: string,
    ): Promise<void> => {
      const search: string[] = [searchValue];
      const newContactInfos: ContactInfos = {
        emails: [],
        telephones: [],
        addressLocalities: [],
        names: [],
      };

      const { data: bilikPeople } = await getBilikPerson({
        variables: {
          value: searchValue,
        },
      });

      const isBilikPerson = Boolean(bilikPeople?.bilikPerson?.length);

      for (let i = 0; i < search.length; i++) {
        if (fakeNumbers.includes(search[i])) {
          // It's a phone number
          setDefaultSearchValueType(search[i], newContactInfos);
          break;
        }

        const { data } = await fetchPrivateIndividuals(search[i]);

        if (data.timelinePrivateIndividual.length > 0) {
          data.timelinePrivateIndividual.forEach(
            ({ givenName, familyName, email, telephone, addressLocality }) => {
              newContactInfos.names.push(
                ((givenName || '') + ' ' + (familyName || '')).trim(),
              );

              if (addressLocality && isNullStringValue(addressLocality)) {
                newContactInfos.addressLocalities.push(addressLocality);
              }

              if (email && isNullStringValue(email)) {
                newContactInfos.emails.push(email);
              }
              if (telephone && isNullStringValue(telephone)) {
                newContactInfos.telephones.push(telephone);
              }
            },
          );
        }

        const contacts = concat(
          newContactInfos.telephones,
          newContactInfos.emails,
        );

        // If we found a bilik person, don't search for more contacts to prevent infinite loop
        if (isBilikPerson) {
          break;
        }

        search.push(...contacts.filter((value) => !search.includes(value)));
      }

      setDefaultSearchValueType(searchValue, newContactInfos);

      setContactInfos({
        emails: uniq(newContactInfos.emails),
        telephones: uniq(newContactInfos.telephones),
        addressLocalities: uniq(newContactInfos.addressLocalities),
        names: uniq(newContactInfos.names),
      });
    };

    findRelatedContactInfos(searchValue);
  }, [searchValue]);

  useEffect(() => {
    const getEvents = async (contactInfos: ContactInfos): Promise<void> => {
      setRefetchEvents(false);
      setTimelineData(undefined);

      const response = await fetchEvents(filters, contactInfos);
      const mongoEvents = filterIgnoredEvents(response);

      setContactedPros(await getContactedPros(contactInfos, fetchEvents));

      const events: MongoEvent[] = mongoEvents;

      const hasUnwantedFilters =
        filters.eventTypes || filters.proPresentationName || filters.telephone;

      if (!hasUnwantedFilters) {
        events.sort((a, b) => {
          return new Date(b.date).getTime() - new Date(a.date).getTime();
        });
      }

      setTimelineData(
        groupBy(mongoEvents, (event) => {
          // Group by date
          return format(
            // Format date
            utcToZonedTime(event.date, 'Europe/Paris'),
            'd MMMM yyyy',
            { locale },
          );
        }),
      ); // Sort by date;
    };
    if (contactInfos) {
      getEvents(contactInfos);
    }
  }, [contactInfos, filters, refetchEvents]);

  if (!contactInfos) {
    return (
      <Loader
        style={{ marginTop: '50px' }}
        size="large"
        active
        inline="centered"
      >
        Chargement...
      </Loader>
    );
  }

  return (
    <TimelineView
      contactInfos={contactInfos}
      filters={filters}
      setFilters={setFilters}
      setRefetchEvents={setRefetchEvents}
      timelineData={timelineData}
      contactedPros={contactedPros}
    />
  );
};
