import {
  useSolicitationAutorespondToggleMutation,
  useSolicitationAutorespondToggleQuery,
} from 'generated/graphql';
import { useCurrentBilikPerson } from 'hooks/use-current-bilik-person/use-current-bilik-person';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { Checkbox, Popup } from 'semantic-ui-react';
import { ToastError, ToastSuccess } from 'utils';
import sendEvent from 'utils/send-event';

type SolicitationAutorespondToggleProps = {
  id: number;
};

const SolicitationAutorespondToggle: FunctionComponent<
  SolicitationAutorespondToggleProps
> = ({ id }) => {
  const [setIsAutorespondActive, { loading: mutating }] =
    useSolicitationAutorespondToggleMutation();
  const { data, loading, error } = useSolicitationAutorespondToggleQuery({
    variables: { id },
  });
  const { currentBilikPerson } = useCurrentBilikPerson();

  const isDisabled = useMemo(
    () =>
      loading ||
      mutating ||
      !!data?.solicitationByPk?.proReviewId ||
      !!data?.solicitationByPk?.declinedBy ||
      data?.solicitationByPk?.proView?.status !== 'published',
    [loading, data, mutating],
  );

  const onChange = useCallback(() => {
    // Care about false value
    if (data?.solicitationByPk?.isAutorespondActive === undefined) {
      return;
    }

    const checked = !data.solicitationByPk.isAutorespondActive;

    setIsAutorespondActive({
      variables: {
        id,
        isAutorespondActive: checked,
      },
    })
      .then((data) => {
        if (!data.data?.updateSolicitationByPk) {
          throw new Error('solicitation not found');
        }

        const solicitation = data.data.updateSolicitationByPk;

        sendEvent(
          'SolicitationToggleAutorespond',
          {
            solicitation,
          },
          {
            pi: {
              email: solicitation.email,
              telephone: solicitation.telephone,
            },
            pro: {
              proPresentation: {
                name: solicitation.proView.proPresentation.name,
                mainTrade: {
                  name: solicitation.proView.proPresentation.mainTrade?.name,
                },
              },
              proView: {
                bilikZone: {
                  mainCityCode: solicitation.proView.bilikZone.mainCityCode,
                },
              },
            },
          },
          {
            bilik: {
              id: currentBilikPerson?.id,
              email: currentBilikPerson?.email,
              givenName: currentBilikPerson?.givenName,
              familyName: currentBilikPerson?.familyName,
            },
          },
          2,
        );
        ToastSuccess(
          'Demande mail',
          `Relance ${checked ? 'activée' : 'désactivée'}`,
        );
      })
      .catch(() => {
        ToastError(
          'Erreur',
          `Impossible ${checked ? "d'activer" : 'de désactiver'} les relances`,
        );
      });
  }, [id, currentBilikPerson, data, setIsAutorespondActive]);

  if (error) return <>Error!</>;
  if (loading || !data?.solicitationByPk) return null;

  return (
    <Popup
      trigger={
        <Checkbox
          toggle
          disabled={isDisabled}
          checked={data.solicitationByPk.isAutorespondActive}
          onChange={onChange}
        />
      }
      content={
        isDisabled
          ? 'Activation/désactivation des relances indisponible (pro non publié, demande refusée, avis déjà publié, ...)'
          : 'Activer/désactiver les relances vers le particulier'
      }
    />
  );
};

export default SolicitationAutorespondToggle;
