import { useAccountProViewDeleteConfirmModalQuery } from 'generated/graphql';
import React, { FunctionComponent } from 'react';
import AccountProViewDeleteConfirmModalContainer from './account-pro-view-delete-confirm-modal-container';
import { AccountProViewDeleteConfirmModalProps } from './account-pro-view-delete-confirm-modal-view';

type AccountProViewDeleteConfirmModalRepositoryProps =
  AccountProViewDeleteConfirmModalProps & {
    currentProViewId?: number;
    authUserId: string;
    onConfirm?: (authUserId: string) => void;
  };

const AccountProViewDeleteConfirmModalRepository: FunctionComponent<
  AccountProViewDeleteConfirmModalRepositoryProps
> = ({ authUserId, currentProViewId, onConfirm, onClose, ...rest }) => {
  const { data } = useAccountProViewDeleteConfirmModalQuery({
    variables: { authUserId },
  });

  return (
    <AccountProViewDeleteConfirmModalContainer
      data={data}
      currentProViewId={currentProViewId}
      authUserId={authUserId}
      onClose={onClose}
      onConfirm={onConfirm}
      {...rest}
    />
  );
};
export default AccountProViewDeleteConfirmModalRepository;
