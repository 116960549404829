import ProPeopleField from 'components/pro-people-field/pro-people-field';
import SelectBilikZoneField from 'components/select-bilik-zone-field/select-bilik-zone-field';
import { useFormikContext } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import { Button, Divider, Form, Icon, Modal } from 'semantic-ui-react';
import { ProFormCreateFields } from './pro-form-create-fields.type';
import { ProFormCreateModalProps } from './pro-form-create-modal-repository';
import slugify from 'slugify';
import FormField from 'components/form-field/form-field';
import AccountsField from 'components/accounts-field/accounts-field';
import TradeFields from 'components/trade-fields/trade-fields';

type ProFormCreateModalViewProps = ProFormCreateModalProps & {
  exceptZones?: number[];
  nextStep: () => void;
  previousStep: () => void;
  step: number;
  proPresentationName?: string;
  action: 'create-pro' | 'add-zone';
};

const ProFormCreateModalView: FunctionComponent<
  ProFormCreateModalViewProps
> = ({
  open,
  exceptZones,
  step,
  nextStep,
  previousStep,
  onClose,
  proPresentationName,
  action,
}) => {
  const {
    handleSubmit,
    handleReset,
    setFieldTouched,
    setFieldValue,
    validateForm,
    submitForm,
    isValidating,
    isSubmitting,
    errors,
    values,
  } = useFormikContext<ProFormCreateFields>();

  return (
    <Modal
      open={open}
      closeIcon
      closeOnDimmerClick={false}
      closeOnEscape={false}
      onClose={onClose}
      size={step === 1 ? 'mini' : undefined}
    >
      <Modal.Header>
        {proPresentationName
          ? `Nouvelle zone : ${proPresentationName}`
          : 'Nouvelle fiche pro'}
      </Modal.Header>
      <Modal.Content scrolling={step === 2}>
        <Form onSubmit={handleSubmit} onReset={handleReset}>
          {step === 1 ? (
            <SelectBilikZoneField name="bilikZoneId" except={exceptZones} />
          ) : null}
          {step === 2 ? (
            <>
              {action === 'create-pro' ? (
                <>
                  <FormField
                    type="text"
                    label="Nom de la fiche"
                    placeholder="Ex: Brunier peinture"
                    name="proPresentation.name"
                    required
                    onChange={(event): void => {
                      setFieldValue('proPresentation.name', event.target.value);
                      setFieldValue(
                        'proPresentation.slug',
                        slugify(event.target.value, {
                          lower: true,
                          // we ignore following characters (*,+,~, ...etc)
                          remove: /[*+~.()'"!:@]/g,
                          locale: 'fr',
                        }),
                      );
                    }}
                  />
                  <FormField
                    type="text"
                    label="Slug"
                    helpText="Le slug est la version normalisée du nom. Il ne contient généralement que des lettres minuscules non accentuées, des chiffres et des traits d’union."
                    placeholder="Ex: brunier-peinture"
                    name="proPresentation.slug"
                    required
                  />
                </>
              ) : null}
              <TradeFields
                bilikZoneId={values.bilikZoneId ?? 0}
                action={action}
              />
              <FormField
                type="email"
                name="emailTarget"
                label="Email cible"
                placeholder="Ex: example@email.fr"
                helpText="Adresse email recevant tous les mails issus de notre plateforme (demande mail, notification d'un nouvel avis)"
                required
              />
            </>
          ) : null}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose}>
          Fermer
        </Button>
        {step === 2 ? (
          <Button type="button" onClick={previousStep}>
            Retour
          </Button>
        ) : null}
        {step === 1 ? (
          <Button
            type="button"
            primary
            loading={isValidating}
            disabled={isValidating || !!errors.bilikZoneId}
            onClick={async (): Promise<void> => {
              const errors = await validateForm();
              setFieldTouched('bilikZoneId', true);
              if (!errors.bilikZoneId) {
                nextStep();
              }
            }}
          >
            Suivant
          </Button>
        ) : null}
        {step === 2 ? (
          <Button
            type="button"
            primary
            loading={isSubmitting}
            disabled={isSubmitting}
            onClick={submitForm}
          >
            <Icon name="save" />
            Enregistrer
          </Button>
        ) : null}
      </Modal.Actions>
    </Modal>
  );
};

export default ProFormCreateModalView;
