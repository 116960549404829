import {
  BilikPersonFormUpdateQuery,
  BilikPersonSetInput,
} from 'generated/graphql';
import React, { FunctionComponent, useCallback } from 'react';
import { ToastError, ToastSuccess } from 'utils/toast';

import { bilikPersonFormValidationSchema } from './bilik-person-form-validation-schema';
import BilikPersonFormView from './bilik-person-form-view';
import { Formik } from 'formik';
import { Loader } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { BilikPersonFormFields } from './bilik-person-form-fields.type';
import { capitalizeName } from 'utils/capitalize';
import { formatE164Telephone } from 'utils';
type BilikPersonFormUpdateProps = {
  updateBilikPerson: (
    bilikPerson: BilikPersonSetInput,
    newPassword?: string,
  ) => Promise<void>;
  formData: BilikPersonFormUpdateQuery['bilikPersonByPk'];
  bilikPersonId: number;
  loading: boolean;
};

const BilikPersonFormUpdateContainer: FunctionComponent<
  BilikPersonFormUpdateProps
> = ({ formData, updateBilikPerson, bilikPersonId, loading }) => {
  const history = useNavigate();

  const onSubmit = useCallback(
    async (values: BilikPersonFormFields, actions): Promise<void> => {
      try {
        await updateBilikPerson(
          {
            id: bilikPersonId,
            givenName: capitalizeName(values.givenName),
            familyName: capitalizeName(values.familyName),
            roleName: values.roleName,
            telephone: formatE164Telephone(values.telephone),
            avatarHash: values.avatarHash,
            employmentDate: values.employmentDate,
            status: values.status,
            description: values.description,
            email: values.email,
          },
          values.newPassword,
        );

        ToastSuccess('Succès', 'Utilisateur enregistré');
        actions.setSubmitting(false);
        history('/bilik_people');
      } catch (error) {
        console.error(error);
        ToastError('Erreur', "Impossible d'enregistrer l'utilisateur");
      }
    },

    [updateBilikPerson, history, bilikPersonId],
  );

  if (loading) {
    return (
      <Loader size="big" active inline="centered" content="Chargement..." />
    );
  }

  return (
    <Formik
      validateOnChange={false}
      validationSchema={bilikPersonFormValidationSchema({
        isCreate: false,
        currentEmail: formData?.email,
      })}
      initialValues={{
        givenName: formData?.givenName || '',
        familyName: formData?.familyName || '',
        roleName: formData?.roleName || '',
        telephone: formData?.telephone || '',
        avatarHash: formData?.avatarHash || '',
        employmentDate: formData?.employmentDate || new Date(),
        status: formData?.status || '',
        description: formData?.description || '',
        email: formData?.email || '',
        newPassword: '',
      }}
      onSubmit={onSubmit}
    >
      {<BilikPersonFormView action="update" />}
    </Formik>
  );
};

export default BilikPersonFormUpdateContainer;
