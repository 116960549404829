import React, { FunctionComponent } from 'react';
import { Serie } from '@nivo/line';
import { Header, Loader, Segment } from 'semantic-ui-react';
import DefaultLineChart from '../default-line-chart';
import NewContactInfoPopup from 'components/new-contact-info-popup/new-contact-info-popup';

type ProContactsGraphsViewProps = {
  newContactChartdata: Serie[] | null;
  avgNewContactByProPublished?: Serie[] | null;
  proPublishedChartData?: Serie[] | null;
};

const ProContactsGraphsView: FunctionComponent<ProContactsGraphsViewProps> = ({
  newContactChartdata,
  avgNewContactByProPublished,
  proPublishedChartData,
}) => {
  return (
    <>
      <Segment>
        <Header>
          Evolution des mises en relation
          <NewContactInfoPopup />
        </Header>
      </Segment>
      <Segment>
        {newContactChartdata ? (
          <DefaultLineChart
            data={newContactChartdata}
            colors={['#e056fd', '#f6ccfe']}
          />
        ) : (
          <div style={{ height: 276 }}>
            <Loader
              size="large"
              active
              inline="centered"
              style={{ marginTop: 224 }}
            />
          </div>
        )}
      </Segment>
      <Segment>
        <Header>Evolution des pros publiés (au 1er du mois)</Header>
      </Segment>
      <Segment>
        {proPublishedChartData ? (
          <DefaultLineChart
            data={proPublishedChartData}
            colors={['#fdcb6e', '#fdd892']}
          />
        ) : (
          <div style={{ height: 276 }}>
            <Loader
              size="large"
              active
              inline="centered"
              style={{ marginTop: 224 }}
            />
          </div>
        )}
      </Segment>
      <Segment>
        <Header>Moyenne des mises en relation par pro publié</Header>
      </Segment>
      <Segment>
        {avgNewContactByProPublished ? (
          <DefaultLineChart
            data={avgNewContactByProPublished}
            colors={['#FF0000', '#FFB7B7']}
          />
        ) : (
          <div style={{ height: 276 }}>
            <Loader
              size="large"
              active
              inline="centered"
              style={{ marginTop: 224 }}
            />
          </div>
        )}
      </Segment>
    </>
  );
};

export default ProContactsGraphsView;
