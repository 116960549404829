import { AccountProViewDeleteConfirmModalQuery } from 'generated/graphql';
import React, { FunctionComponent } from 'react';
import { Button, Modal } from 'semantic-ui-react';

export type AccountProViewDeleteConfirmModalProps = {
  open: boolean;
  onClose?: () => void;
};

type AccountProViewDeleteConfirmModalViewProps =
  AccountProViewDeleteConfirmModalProps & {
    proViews: AccountProViewDeleteConfirmModalQuery['proView'];
    confirmDelete: () => void;
  };

const AccountProViewDeleteConfirmModalView: FunctionComponent<
  AccountProViewDeleteConfirmModalViewProps
> = ({ proViews, open, onClose, confirmDelete }) => (
  <Modal
    onClose={onClose}
    open={open}
    closeOnDimmerClick={false}
    closeOnEscape={false}
    closeIcon
  >
    <Modal.Header>Attention</Modal.Header>
    <Modal.Content>
      <p>
        Cet <b>accès à l&apos;espace pro</b> est utilisé par{' '}
        <b>d&apos;autres</b> fiches :
      </p>
      <ul>
        {proViews.map((proView) => (
          <li key={`account-linked-pro-view-${proView.id}`}>
            <a
              href={`/pros/update/${proView.id}/zone`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {proView.proPresentation.name} -{' '}
              {proView.proPresentation.mainTrade?.name} -{' '}
              {proView.bilikZone.mainCityCode}
            </a>
          </li>
        ))}
      </ul>
      <p>
        <b> Pense donc à mettre à jour les fiches si nécéssaire !</b>
      </p>
    </Modal.Content>
    <Modal.Actions>
      <Button
        onClick={(): void => {
          onClose?.();
        }}
      >
        Annuler
      </Button>
      <Button negative onClick={confirmDelete}>
        Supprimer
      </Button>
    </Modal.Actions>
  </Modal>
);

export default AccountProViewDeleteConfirmModalView;
