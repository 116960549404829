import { QueryResult } from '@apollo/client';
import {
  useStopSmsCreateMutation,
  useStopSmsLazyQuery,
  useStopSmsDeleteMutation,
  StopSmsQuery,
  Exact,
} from 'generated/graphql';
import { useCurrentBilikPerson } from 'hooks/use-current-bilik-person/use-current-bilik-person';
import React, { FunctionComponent, useCallback } from 'react';
import sendEvent from 'utils/send-event';
import { StopSmsContainer } from './stop-sms-container';

type StopSmsRepositoryProps = {
  triggerNotStopped: React.ReactElement;
  triggerStopped: React.ReactElement;
  telephone?: string;
  onActionTriggered?: () => void;
};

export const StopSmsRepository: FunctionComponent<StopSmsRepositoryProps> = ({
  telephone,
  triggerNotStopped,
  triggerStopped,
  onActionTriggered,
}) => {
  const { currentBilikPerson } = useCurrentBilikPerson();

  const [StopSmsQuery, { data }] = useStopSmsLazyQuery();

  const fetchStopSmss = useCallback(
    async (
      telephones: string[],
    ): Promise<
      QueryResult<
        StopSmsQuery,
        Exact<{
          telephones?: string | string[] | undefined;
        }>
      >
    > =>
      await StopSmsQuery({
        variables: {
          telephones,
        },
      }),
    [],
  );

  const [insertStopSmsOne] = useStopSmsCreateMutation();

  const [deleteByPkStopSms] = useStopSmsDeleteMutation();

  const insertStopSms = useCallback(
    async (telephone: string) => {
      const response = await insertStopSmsOne({
        variables: { object: { telephone } },
      });

      await sendEvent(
        'StopSmsCreated',
        {
          stopSms: response.data?.insertStopSmsOne,
        },
        {
          pi: {
            telephone: response.data?.insertStopSmsOne?.telephone,
          },
        },
        {
          bilik: {
            id: currentBilikPerson?.id,
            email: currentBilikPerson?.email,
            givenName: currentBilikPerson?.givenName,
            familyName: currentBilikPerson?.familyName,
          },
        },
      );
    },
    [currentBilikPerson],
  );

  const deleteStopSms = useCallback(
    async (telephone: string) => {
      const response = await deleteByPkStopSms({
        variables: { telephone: telephone },
      });

      await sendEvent(
        'StopSmsDeleted',
        {
          stopSms: response.data?.deleteStopSms?.returning[0],
        },
        {
          pi: {
            telephone: response.data?.deleteStopSms?.returning[0].telephone,
          },
        },
        {
          bilik: {
            id: currentBilikPerson?.id,
            email: currentBilikPerson?.email,
            givenName: currentBilikPerson?.givenName,
            familyName: currentBilikPerson?.familyName,
          },
        },
      );
    },
    [currentBilikPerson],
  );

  return (
    <StopSmsContainer
      fetchStopSms={fetchStopSmss}
      telephone={telephone}
      stopSmss={data?.stopSms}
      insertStopSms={insertStopSms}
      deleteStopSms={deleteStopSms}
      triggerNotStopped={triggerNotStopped}
      triggerStopped={triggerStopped}
      onActionTriggered={onActionTriggered}
    />
  );
};
