import FieldError from 'components/field-error/field-error';
import ProPersonFormModal from 'components/pro-person-form-modal/pro-person-form-modal';
import { ProPersonSearchFilter } from 'components/pro-person-search-filter';
import { getIn, useFormikContext } from 'formik';
import {
  ProPeopleFieldQuery,
  useProPeopleFieldLinkProPersonMutation,
  useProPeopleFieldQuery,
} from 'generated/graphql';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { equals } from 'remeda';
import {
  Button,
  Checkbox,
  Container,
  Icon,
  Loader,
  Message,
  Table,
} from 'semantic-ui-react';
import {
  formatTelephone,
  ToastError,
  ToastInfo,
  ToastSuccess,
  ToastWarn,
} from 'utils';
import { ProViewProPersonDeleteConfirmModal } from './pro-view-pro-person-delete-confirm-modal';

type ModalState = {
  open: boolean;
  id?: number;
  email?: string;
};

type ProPeopleFieldProps = {
  name: string;
  currentProViewId?: number;
  onProPeopleLoaded?: (
    proPeople: ProPeopleFieldQuery['proPerson'][0][],
  ) => void;
};

const ProPeopleField: FunctionComponent<ProPeopleFieldProps> = ({
  name,
  onProPeopleLoaded,
  currentProViewId,
}) => {
  const { setFieldValue, setFieldTouched, values, errors, touched } =
    useFormikContext();

  const [proPersonModal, setProPersonModal] = useState<ModalState>({
    open: false,
  });

  const [
    proViewProPersonDeleteConfirmModal,
    setProViewProPersonDeleteConfirmModal,
  ] = useState<ModalState>({
    open: false,
  });

  const proPeopleValues = useMemo(() => getIn(values, name), [values, name]);
  const [proPeopleIds, setProPeopleIds] = useState<number[]>([]);

  const [linkProPersonMutation] = useProPeopleFieldLinkProPersonMutation();

  const handleLinkProViewProPerson = useCallback(
    async (proPersonId: number) => {
      // Check if contact already exist, is so, do nothing and warn user
      if (proPeopleValues.some((person) => person.id === proPersonId)) {
        ToastWarn('Attention', 'Contact déjà présent sur la fiche');
        return;
      }

      if (currentProViewId) {
        try {
          await linkProPersonMutation({
            variables: {
              objects: [
                {
                  proPersonId: proPersonId,
                  proViewId: currentProViewId,
                  position: proPeopleValues.length + 1,
                  isVisible: true,
                },
              ],
            },
          });
          ToastSuccess('Succès', 'Contact enregistré sur la fiche');
        } catch (error) {
          console.log(error);
          ToastError(
            'Erreur',
            "Impossible d'enregistrer le contact sur la fiche",
          );
        }
      }

      setFieldTouched(name);
      setFieldValue(
        name,
        [
          ...proPeopleValues,
          {
            id: proPersonId,
            position: proPeopleValues.length + 1,
            isVisible: true,
          },
        ],
        true,
      );
    },
    [proPeopleValues, name, setFieldTouched, setFieldValue],
  );

  // On value change check if old ids are equals to new ids (to avoid refreshing)
  useEffect(() => {
    const newProPeopleIds = proPeopleValues
      .map((proPerson) => proPerson.id)
      .sort();
    if (!equals(newProPeopleIds, proPeopleIds)) {
      setProPeopleIds(newProPeopleIds); // Refetch
    }
  }, [proPeopleValues]);

  const { data } = useProPeopleFieldQuery({
    variables: {
      ids: proPeopleIds,
    },
  });

  const proPeople = useMemo(() => data?.proPerson, [data]);

  useEffect(() => {
    if (proPeople && onProPeopleLoaded) {
      onProPeopleLoaded(proPeople);
    }
  }, [proPeople]);

  if (!proPeople) {
    return (
      <Loader
        style={{ marginTop: '50px' }}
        size="large"
        active
        inline="centered"
      >
        Chargement...
      </Loader>
    );
  }

  return (
    <>
      {proPeople && proPeople.length > 0 ? (
        <SortableTable
          name={name}
          setProPersonModal={setProPersonModal}
          setProViewProPersonDeleteConfirmModal={
            setProViewProPersonDeleteConfirmModal
          }
          proViewProPersonDeleteConfirmModal={
            proViewProPersonDeleteConfirmModal
          }
          proPersonModal={proPersonModal}
          proPeople={proPeople}
          items={proPeopleValues}
          onSortEnd={({ oldIndex, newIndex }): void => {
            const newValues = arrayMove(
              proPeopleValues,
              oldIndex,
              newIndex,
            ).map((proPerson: any, index) => {
              return {
                id: proPerson.id,
                isVisible: proPerson.isVisible,
                position: index + 1,
              };
            });
            setFieldTouched(name);
            setFieldValue(name, newValues, true);
          }}
          useDragHandle
          useWindowAsScrollContainer
        />
      ) : (
        <Message>Aucun contacts</Message>
      )}
      <Container fluid textAlign="right">
        <ProPersonSearchFilter
          style={{ marginRight: '15px' }}
          placeholder="Nom, email..."
          onResultSelect={(_, { result }): void => {
            handleLinkProViewProPerson(result.value);
          }}
        />
        <Button
          positive
          icon
          type="button"
          style={{ minWidth: '45px', minHeight: '32px' }}
          onClick={(): void => {
            setProPersonModal({ open: true });
          }}
        >
          <Icon name="plus" />
        </Button>
      </Container>
      {errors[name] && touched[name] ? (
        <FieldError>{errors[name]}</FieldError>
      ) : null}
      <ProPersonFormModal
        open={proPersonModal.open}
        proPersonId={proPersonModal.id}
        onClose={(): void => {
          setProPersonModal({ open: false });
        }}
        onProPersonCreated={handleLinkProViewProPerson}
        onProPersonUpdated={(): void => {
          ToastInfo(
            'Info',
            "Pense à mettre à jour les accès à l'espace pro si nécéssaire (changement d'email)!",
          );
        }}
      />
      {proViewProPersonDeleteConfirmModal.id ? (
        <ProViewProPersonDeleteConfirmModal
          open={proViewProPersonDeleteConfirmModal.open}
          onClose={(): void => {
            setProViewProPersonDeleteConfirmModal({ open: false });
          }}
          onConfirm={(proPersonId): void => {
            setFieldTouched(name);
            setFieldValue(
              name,
              proPeopleValues.filter(
                (proPerson) => proPerson.id !== proPersonId,
              ),
              true,
            );
            ToastInfo(
              'Contact retiré',
              currentProViewId ? 'Pense à enregistrer la fiche' : null,
            );
          }}
          proPersonId={proViewProPersonDeleteConfirmModal.id}
          proPersonEmail={proViewProPersonDeleteConfirmModal.email}
          currentProViewId={currentProViewId}
        />
      ) : null}
    </>
  );
};

interface SortableTableProps {
  name;
  items;
  proPeople;
  setProPersonModal;
  setProViewProPersonDeleteConfirmModal;
  proViewProPersonDeleteConfirmModal;
  proPersonModal;
}

const SortableTable = SortableContainer<SortableTableProps>(
  ({
    name,
    items,
    proPeople,
    setProPersonModal,
    setProViewProPersonDeleteConfirmModal,
    proViewProPersonDeleteConfirmModal,
    proPersonModal,
  }) => {
    return (
      <Table basic="very" selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">#</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Visible</Table.HeaderCell>
            <Table.HeaderCell>Prénom</Table.HeaderCell>
            <Table.HeaderCell>Nom</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Téléphone</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map(({ id, isVisible }, index) => {
            const proPerson = proPeople.find(
              (proPerson) => proPerson.id === id,
            );

            if (!proPerson) {
              return;
            }

            return (
              <SortableItem
                key={`pro-person-field-${proPerson.id}`}
                name={name}
                items={items}
                index={index}
                isVisible={isVisible}
                proPerson={proPerson}
                proViewProPersonDeleteConfirmModal={
                  proViewProPersonDeleteConfirmModal
                }
                proPersonModal={proPersonModal}
                setProPersonModal={setProPersonModal}
                setProViewProPersonDeleteConfirmModal={
                  setProViewProPersonDeleteConfirmModal
                }
              />
            );
          })}
        </Table.Body>
      </Table>
    );
  },
);

interface SortableItemProps {
  items;
  name;
  proPerson;
  isVisible;
  setProPersonModal;
  proViewProPersonDeleteConfirmModal;
  proPersonModal;
  setProViewProPersonDeleteConfirmModal;
}

const SortableItem = SortableElement<SortableItemProps>(
  ({
    items,
    name,
    proPerson,
    isVisible,
    setProPersonModal,
    proViewProPersonDeleteConfirmModal,
    proPersonModal,
    setProViewProPersonDeleteConfirmModal,
  }) => {
    const { setFieldValue, setFieldTouched } = useFormikContext();

    return (
      <Table.Row>
        <DragHandle />
        <Table.Cell textAlign="center">
          <Checkbox
            checked={isVisible}
            onChange={(event, { checked }): void => {
              setFieldTouched(name, true);
              setFieldValue(
                name,
                items.map((item) => {
                  if (item.id === proPerson.id) {
                    item.isVisible = checked;
                  }
                  return item;
                }),
                true,
              );
            }}
          />
        </Table.Cell>
        <Table.Cell>{proPerson.givenName}</Table.Cell>
        <Table.Cell>{proPerson.familyName}</Table.Cell>
        <Table.Cell>{proPerson.email}</Table.Cell>
        <Table.Cell>
          {proPerson.telephone ? formatTelephone(proPerson.telephone) : null}
        </Table.Cell>
        <Table.Cell textAlign="right">
          <Button
            icon
            negative
            type="button"
            basic
            style={{ minWidth: '35px', minHeight: '32px' }}
            loading={proViewProPersonDeleteConfirmModal.open}
            onClick={(): void => {
              setProViewProPersonDeleteConfirmModal({
                open: true,
                id: proPerson.id,
                email: proPerson.email,
              });
            }}
          >
            <Icon name="trash alternate outline" />
          </Button>
          <Button
            icon
            primary
            style={{ minWidth: '35px', minHeight: '32px' }}
            type="button"
            loading={proPersonModal.open}
            onClick={(): void => {
              setProPersonModal({ open: true, id: proPerson.id });
            }}
          >
            <Icon name="pencil alternate" />
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  },
);

const DragHandle = SortableHandle(() => (
  <Table.Cell textAlign="center">
    <Icon name="bars" />
  </Table.Cell>
));

export default ProPeopleField;
