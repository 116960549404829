import {
  ProViewSetInput,
  useProFormArchiveModalQuery,
  useProFormArchiveModalUpdateMutation,
} from 'generated/graphql';
import React, { FunctionComponent, useCallback } from 'react';
import ProFormArchiveModalContainer from './pro-form-archive-modal-container';

type ProFormArchiveModalRepositoryProps = ProFormArchiveModalProps & {
  proViewId: number;
  proOrganizationId?: number;
  onProArchived?: (proViewId: number) => void;
};

export type ProFormArchiveModalProps = {
  open: boolean;
  onClose?: () => void;
};

export type ProFormArchiveFields = {
  proViewIds: number[];
  dateArchiveScheduled?: Date;
};

export const ProFormArchiveModalRepository: FunctionComponent<
  ProFormArchiveModalRepositoryProps
> = ({ proViewId, proOrganizationId, ...rest }) => {
  const { data, refetch } = useProFormArchiveModalQuery({
    variables: {
      proOrganizationId: proOrganizationId ?? 0,
      proViewId: proViewId,
    },
  });

  const [proFormArchiveModalUpdate] = useProFormArchiveModalUpdateMutation();

  const updateProView = useCallback(
    async (proViewIds: number[], set: ProViewSetInput) => {
      const { data } = await proFormArchiveModalUpdate({
        variables: { proViewIds, set },
      });

      return data?.updateProView?.returning.map(({ id }) => id);
    },
    [proFormArchiveModalUpdate],
  );

  return (
    <ProFormArchiveModalContainer
      proViews={data?.proOrganization?.proPresentations.flatMap(
        ({ proViews }) => proViews,
      )}
      proViewId={proViewId}
      proOrganizationId={proOrganizationId}
      updateProView={updateProView}
      refetch={refetch}
      {...rest}
    />
  );
};
