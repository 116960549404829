import FormField from 'components/form-field/form-field';
import { useFormikContext } from 'formik';
import { ProFormArchiveModalQuery } from 'generated/graphql';
import React, { FunctionComponent, useMemo } from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';

import {
  ProFormArchiveFields,
  ProFormArchiveModalProps,
} from './pro-form-archive-modal-repository';
import { fr } from 'date-fns/locale';
import { addMonths, endOfMonth, format, startOfMonth } from 'date-fns';

type ProFormArchiveModalViewProps = ProFormArchiveModalProps & {
  proViews?: NonNullable<
    ProFormArchiveModalQuery['proOrganization']
  >['proPresentations'][0]['proViews'];
  proViewId: number;
  onCancelSchedule: (proViewId: number) => void;
};

const ProFormArchiveModalView: FunctionComponent<
  ProFormArchiveModalViewProps
> = ({ proViews, proViewId, open, onClose, onCancelSchedule }) => {
  const { handleSubmit, handleReset, values, isSubmitting, submitForm } =
    useFormikContext<ProFormArchiveFields>();

  const otherProViews = useMemo(
    () => proViews?.filter((proView) => proView.id !== proViewId),
    [proViews],
  );

  return (
    <Modal
      open={open}
      closeIcon
      closeOnDimmerClick={false}
      closeOnEscape={false}
      onClose={onClose}
      size={undefined}
    >
      <Modal.Header>Archiver</Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit} onReset={handleReset}>
          <p>Liste des actions provoquées par l&apos;archivage de la fiche :</p>
          <ul>
            <>
              <li>
                Un mail sera envoyé à tous les particuliers ayant un suivi
                actif.
              </li>
              <li>
                Un sms sera envoyé à tous les particuliers l&apos;ayant contacté
                les 4 derniers mois.
              </li>
              <li>
                Vous recevrez un mail listant les particuliers avec une ligne
                fixe à prévenir
              </li>
            </>
            <li>Tous les abonnements liés à cette fiche seront désactivés.</li>
            <li>
              Le numéro Call Tracking sera redirigé vers &quot;Pro plus dans
              Bilik&quot;.
            </li>
            <li>La fiche ne sera plus visible sur bilik.fr.</li>
            <li>
              Toutes les relances (mails, avis, demande d&apos;avis) seront
              désactivées.
            </li>
            <li>
              Les accès à l&apos;espace pro liès à cette fiche seront supprimés.
            </li>
            <li>La date d&apos;archive sera affectée à la date courante.</li>
            <li>
              La fiche sera enlevée des collegues sur lesquels elle était
              présente.
            </li>
          </ul>
          {otherProViews?.length ? (
            <FormField
              type="checkboxes"
              label="Archiver d'autres fiches simultanément ?"
              helpText="Les particuliers ne seront prévenus qu'une seule fois pour toutes les fiches archivées."
              name="proViewIds"
              options={otherProViews.map((proView) => ({
                key: proView.id,
                value: proView.id,
                label: `${proView.proPresentation.mainTrade?.name} - ${proView.bilikZone.name}`,
              }))}
            />
          ) : null}
          <FormField
            type="date"
            label="Renseignez une date si vous souhaitez programmer l'archivage"
            helpText="Les fiches sont archivées à 6h UTC de la date demandée"
            placeholder={format(endOfMonth(new Date()), 'dd/MM/yyyy')}
            name="dateArchiveScheduled"
            minDate={new Date()}
            locale={fr}
            values={values}
            dateFormat="dd/MM/yyyy"
            clearable
          />
          {proViews?.some((proView) => proView.dateArchiveScheduled) && (
            <div style={{ color: 'red' }}>
              Archivage(s) programmé(s) :{' '}
              {
                <>
                  <ul>
                    {proViews
                      .filter((proView) => proView.dateArchiveScheduled)
                      .map((proView) => (
                        <li key={proView.id}>
                          {format(
                            new Date(proView.dateArchiveScheduled),
                            'dd/MM/yyyy',
                          )}{' '}
                          : {proView.proPresentation.mainTrade?.name} -{' '}
                          {proView.bilikZone.name} -{' '}
                          <a
                            href="#"
                            onClick={() => onCancelSchedule(proView.id)}
                          >
                            Annuler
                          </a>
                        </li>
                      ))}
                  </ul>
                  <p>
                    Tu peux reprogrammer une date ou archiver directement si
                    nécessaire.
                  </p>
                </>
              }
            </div>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose}>
          Fermer
        </Button>
        <Button
          type="button"
          primary
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={submitForm}
        >
          <Icon name="save" />
          {values.dateArchiveScheduled ? (
            <>Programmer l&apos;archivage</>
          ) : (
            <>Archiver</>
          )}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ProFormArchiveModalView;
