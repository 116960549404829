import {
  useProReviewInvitationToggleMutation,
  useProReviewInvitationToggleQuery,
} from 'generated/graphql';
import { useCurrentBilikPerson } from 'hooks/use-current-bilik-person/use-current-bilik-person';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { Checkbox, Popup } from 'semantic-ui-react';
import { ToastError, ToastSuccess } from 'utils';
import sendEvent from 'utils/send-event';

type ProReviewInvitationAutorespondToggleProps = {
  id: number;
};

export const ProReviewInvitationAutorespondToggle: FunctionComponent<
  ProReviewInvitationAutorespondToggleProps
> = ({ id }) => {
  const [setIsAutorespondActive, { loading: mutating }] =
    useProReviewInvitationToggleMutation();
  const { data, loading, error } = useProReviewInvitationToggleQuery({
    variables: { id },
  });
  const { currentBilikPerson } = useCurrentBilikPerson();

  const isDisabled = useMemo(
    () =>
      loading ||
      mutating ||
      !!data?.proReviewInvitationByPk?.proReviewId ||
      data?.proReviewInvitationByPk?.isBounce ||
      data?.proReviewInvitationByPk?.proPresentation?.proViews?.every(
        (proView) => proView.status === 'archived',
      ),
    [loading, mutating, data],
  );

  const onChange = useCallback(() => {
    // Care about false value
    if (data?.proReviewInvitationByPk?.isAutorespondActive === undefined) {
      return;
    }

    const checked = !data.proReviewInvitationByPk.isAutorespondActive;

    setIsAutorespondActive({
      variables: {
        id,
        isAutorespondActive: checked,
      },
    })
      .then((data) => {
        if (!data.data?.updateProReviewInvitationByPk) {
          throw new Error('pro review invitation not found');
        }

        const proReviewInvitation = data.data.updateProReviewInvitationByPk;

        sendEvent(
          'ProReviewInvitationToggleAutorespond',
          {
            proReviewInvitation,
          },
          {
            pi: {
              email: proReviewInvitation?.email,
            },
            pro: {
              proPresentation: {
                name: proReviewInvitation?.proPresentation?.name,
                mainTrade: {
                  name: proReviewInvitation?.proPresentation?.mainTrade?.name,
                },
              },
            },
          },
          {
            bilik: {
              id: currentBilikPerson?.id,
              email: currentBilikPerson?.email,
              givenName: currentBilikPerson?.givenName,
              familyName: currentBilikPerson?.familyName,
            },
          },
          2,
        );
        ToastSuccess(
          'Demande mail',
          `Relance ${checked ? 'activée' : 'désactivée'}`,
        );
      })
      .catch(() => {
        ToastError(
          'Erreur',
          `Impossible ${checked ? "d'activer" : 'de désactiver'} les relances`,
        );
      });
  }, [id, currentBilikPerson, data, setIsAutorespondActive]);

  if (error) return <>Error!</>;
  if (loading || !data?.proReviewInvitationByPk) return null;

  return (
    <Popup
      trigger={
        <Checkbox
          toggle
          disabled={isDisabled}
          checked={data.proReviewInvitationByPk.isAutorespondActive}
          onChange={onChange}
        />
      }
      content={
        isDisabled
          ? 'Activation/désactivation de la relance indisponible (avis déjà réalisé, pro archivé, email particulier invalide, ...)'
          : 'Activer/désactiver la relance vers le particulier'
      }
    />
  );
};
