import {
  BilikPersonSetInput,
  useBilikPersonFormUpdateQuery,
  useUseBilikPersonFormUpdateMutation,
} from 'generated/graphql';
import React, { FunctionComponent, useCallback } from 'react';
import BilikPersonFormUpdateContainer from './bilik-person-form-update-container';
import { useParams } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import { authUpdateUserMutation } from 'generated/api-client/@tanstack/react-query.gen';
import {
  authAddUserToGroup,
  authGetGroup,
  authRemoveUserFromGroup,
} from 'generated/api-client';
import { ToastError } from 'utils';

const BilikPersonFormUpdateRepository: FunctionComponent = () => {
  const params = useParams();
  const bilikPersonId = Number(params.bilikPersonId);

  const { loading, data } = useBilikPersonFormUpdateQuery({
    variables: {
      id: Number(bilikPersonId),
    },
    fetchPolicy: 'network-only',
  });

  const [updateByPkBilikPerson] = useUseBilikPersonFormUpdateMutation();

  const { mutateAsync: updateAuthUser } = useMutation(authUpdateUserMutation());

  const updateBilikPerson = useCallback(
    async (bilikPerson: BilikPersonSetInput, newPassword?: string) => {
      if (!data?.bilikPersonByPk) {
        throw Error('Cannot find current Bilik Person');
      }
      const oldBilikPerson = data.bilikPersonByPk;

      if (bilikPerson.roleName !== oldBilikPerson.roleName) {
        const oldGroupPath =
          oldBilikPerson.roleName === 'user'
            ? '/bilik-people/managers'
            : '/bilik-people/admins';

        const newGroupPath =
          bilikPerson.roleName === 'user'
            ? '/bilik-people/managers'
            : '/bilik-people/admins';

        const oldGroup = await authGetGroup({
          path: {
            path: oldGroupPath,
          },
        });

        const newGroup = await authGetGroup({
          path: {
            path: newGroupPath,
          },
        });

        if (!oldGroup.data?.id || !newGroup.data?.id) {
          throw Error('Group not found');
        }

        await authRemoveUserFromGroup({
          path: {
            groupId: oldGroup.data.id,
            userId: oldBilikPerson.authUserId,
          },
        });

        await authAddUserToGroup({
          path: {
            groupId: newGroup.data.id,
            userId: oldBilikPerson.authUserId,
          },
        });
      }

      if (oldBilikPerson.email !== bilikPerson.email || !!newPassword) {
        await updateAuthUser({
          path: { userId: oldBilikPerson.authUserId },
          body: {
            email: bilikPerson.email,
            password: !!newPassword
              ? {
                  value: newPassword,
                  temporary: false,
                }
              : undefined,
          },
        });
      }

      await updateByPkBilikPerson({
        variables: {
          bilikPersonId: bilikPersonId,
          bilikPerson: {
            givenName: bilikPerson.givenName,
            familyName: bilikPerson.familyName,
            roleName: bilikPerson.roleName,
            telephone: bilikPerson.telephone,
            avatarHash: bilikPerson.avatarHash,
            employmentDate: bilikPerson.employmentDate,
            status: bilikPerson.status,
            description: bilikPerson.description,
            email: bilikPerson.email,
          },
        },
      });
    },
    [updateByPkBilikPerson, data?.bilikPersonByPk],
  );

  return (
    <BilikPersonFormUpdateContainer
      updateBilikPerson={updateBilikPerson}
      formData={data?.bilikPersonByPk}
      bilikPersonId={Number(bilikPersonId)}
      loading={loading}
    />
  );
};

export default BilikPersonFormUpdateRepository;
