import {
  BilikPersonInsertInput,
  useBilikPersonFormCreateMutation,
} from 'generated/graphql';
import React, { FunctionComponent, useCallback } from 'react';
import BilikPersonFormCreateContainer from './bilik-person-form-create-container';
import { useMutation } from '@tanstack/react-query';
import { authCreateUserMutation } from 'generated/api-client/@tanstack/react-query.gen';

const BilikPersonFormCreateRepository: FunctionComponent = () => {
  const [insertBilikPersonOne] = useBilikPersonFormCreateMutation();

  const { mutateAsync: createAuthUser } = useMutation(authCreateUserMutation());

  const createBilikPerson = useCallback(
    async (bilikPerson: BilikPersonInsertInput, newPassword: string) => {
      if (!newPassword || !bilikPerson.roleName || !bilikPerson.email) {
        return;
      }

      const groupName =
        bilikPerson.roleName === 'user'
          ? '/bilik-people/managers'
          : '/bilik-people/admins';

      const accountId = await createAuthUser({
        body: {
          email: bilikPerson.email,
          groups: [groupName],
          password: {
            value: newPassword,
            temporary: false,
          },
        },
      });

      const newBilikPerson: BilikPersonInsertInput = {
        ...bilikPerson,
        authUserId: accountId,
      };

      await insertBilikPersonOne({
        variables: {
          object: newBilikPerson,
        },
      });
    },
    [insertBilikPersonOne, createAuthUser],
  );

  return (
    <BilikPersonFormCreateContainer createBilikPerson={createBilikPerson} />
  );
};

export default BilikPersonFormCreateRepository;
