import { OrderBy, useBilikZonesTableQuery } from 'generated/graphql';
import React, { FunctionComponent, useMemo } from 'react';

import BilikZonesTableContainer from './bilik-zones-table-container';
import { useCurrentBilikPerson } from 'hooks/use-current-bilik-person/use-current-bilik-person';

const BilikZonesTableRepository: FunctionComponent<unknown> = () => {
  const { currentBilikPerson } = useCurrentBilikPerson();

  const { loading, data } = useBilikZonesTableQuery({
    variables: {
      order_by: [{ name: OrderBy.Asc }],
      where:
        currentBilikPerson?.roleName !== 'admin'
          ? { status: { _eq: 'published' } }
          : {},
    },
  });

  return (
    <BilikZonesTableContainer
      loading={loading}
      bilikZones={data?.bilikZone ?? []}
    />
  );
};

export default BilikZonesTableRepository;
