import { Formik, FormikHelpers } from 'formik';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { ToastError, ToastSuccess } from 'utils/toast';
import { bilikPersonFormValidationSchema } from './bilik-person-form-validation-schema';
import BilikPersonFormView from './bilik-person-form-view';
import { BilikPersonInsertInput } from 'generated/graphql';
import { useNavigate } from 'react-router-dom';
import { BilikPersonFormFields } from './bilik-person-form-fields.type';
import { capitalizeName } from 'utils/capitalize';
import { formatE164Telephone } from 'utils';

type BilikPersonFormCreateProps = {
  createBilikPerson: (
    bilikPerson: BilikPersonInsertInput,
    newPassword: string,
  ) => Promise<void>;
};

const BilikPersonFormCreateContainer: FunctionComponent<
  BilikPersonFormCreateProps
> = ({ createBilikPerson }) => {
  const history = useNavigate();

  const initialValues: BilikPersonFormFields = useMemo(() => {
    return {
      givenName: '',
      familyName: '',
      roleName: '',
      telephone: '',
      avatarHash: '',
      employmentDate: new Date(),
      status: '',
      description: '',
      email: '',
      newPassword: '',
    };
  }, []);

  const onSubmit = useCallback(
    async (
      values: BilikPersonFormFields,
      actions: FormikHelpers<BilikPersonFormFields>,
    ): Promise<void> => {
      try {
        await createBilikPerson(
          {
            givenName: capitalizeName(values.givenName),
            familyName: capitalizeName(values.familyName),
            roleName: values.roleName,
            telephone: formatE164Telephone(values.telephone),
            avatarHash: values.avatarHash,
            employmentDate: values.employmentDate,
            status: values.status,
            description: values.description,
            email: values.email,
          },
          values.newPassword,
        );

        ToastSuccess('Succès', 'Utilisateur enregistré');
        actions.setSubmitting(false);
        history('/bilik_people');
      } catch (err) {
        console.error(err);
        ToastError('Erreur', "Impossible d'enregistrer l'utilisateur");
      }
    },
    [],
  );

  return (
    <Formik
      validateOnChange={false}
      validationSchema={bilikPersonFormValidationSchema({ isCreate: true })}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {<BilikPersonFormView action="create" />}
    </Formik>
  );
};

export default BilikPersonFormCreateContainer;
