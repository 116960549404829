import React, { FunctionComponent } from 'react';
import { ResponsiveLine } from '@nivo/line';
import SliceTooltip from '../slice-tooltip/slice-tooltip';
import { Serie } from '@nivo/line';
import DefaultLineChart from '../default-line-chart';

type StatsGlobalSolicitationsContainerProps = {
  data: Serie[];
};

const SolicitationsGraphView: FunctionComponent<
  StatsGlobalSolicitationsContainerProps
> = ({ data }) => {
  return <DefaultLineChart data={data} colors={['#ff6600', '#ffb380']} />;
};
export default SolicitationsGraphView;
