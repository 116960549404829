import { ResponsiveBar } from '@nivo/bar';
import { apiClient } from 'axios-client';
import {
  eachMonthOfInterval,
  endOfMonth,
  format,
  startOfMonth,
  subMonths,
} from 'date-fns';
import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Container,
  Header,
  Loader,
  Message,
  Segment,
  Statistic,
} from 'semantic-ui-react';

interface ProStatsNewContactsProps {
  startDate: Date;
  endDate: Date;
  proViewId: number;
}

type ChartData = {
  Date: string;
  dateISO: string;
  'Mises en relations': number;
}[];

const ProStatsNewContacts: FunctionComponent<ProStatsNewContactsProps> = ({
  endDate,
  startDate,
  proViewId,
}) => {
  const [chartData, setChartData] = useState<ChartData | null>(null);
  const [totalCount, setTotalCount] = useState<number>(0);

  useEffect(() => {
    apiClient
      .post('event/stats', {
        proViewId,
        after: subMonths(startDate, 1),
        before: endDate,
      })
      .then(async (response) => {
        const months = eachMonthOfInterval({ start: startDate, end: endDate });
        const currentMonth = format(new Date(), 'yyyy-MM');

        const data = await Promise.all(
          months.map(async (month) => {
            const monthString = format(month, 'MM/yyyy');

            const monthData = response.data.find(
              (data) => format(new Date(data.month), 'MM/yyyy') === monthString,
            );

            if (monthData?.newContacts) {
              return {
                Date: monthString,
                dateISO: format(month, 'yyyy-MM'),
                'Mises en relations': monthData.newContacts,
              };
            } else {
              const monthIso = format(month, 'yyyy-MM');

              if (currentMonth !== monthIso) {
                return {
                  Date: monthString,
                  dateISO: monthIso,
                  'Mises en relations': 0,
                };
              }

              // For the current month, data is not cached yet so we have to compute it manually using new-contacts route
              const currentMonthNewContactCount = await apiClient
                .post<number>('/form/pro-view/new-contacts', {
                  proViewId,
                  after: startOfMonth(month),
                  before: endOfMonth(month),
                })
                .then((result) => result.data);

              return {
                Date: monthString,
                dateISO: format(month, 'yyyy-MM'),
                'Mises en relations': currentMonthNewContactCount,
              };
            }
          }),
        );

        data.sort((a, b) => a.dateISO.localeCompare(b.dateISO));

        const totalCount = data.reduce(
          (acc, value) => acc + value['Mises en relations'],
          0,
        );
        setTotalCount(totalCount);

        setChartData(data);
      });
  }, [startDate, endDate, proViewId]);

  if (!chartData) {
    return (
      <Segment style={{ height: '576px' }}>
        <Loader active>Chargement...</Loader>
      </Segment>
    );
  }

  if (totalCount === 0) {
    return (
      <Container fluid style={{ height: '576px' }}>
        <Header as="h2">Mises en relations</Header>
        <Message>Pas de stats</Message>
      </Container>
    );
  }

  return (
    <Container fluid>
      <Header>Mises en relations</Header>
      <br />
      <Statistic.Group size="tiny">
        <Statistic horizontal>
          <Statistic.Value style={{ color: '#e056fd' }}>
            {totalCount}
          </Statistic.Value>
          <Statistic.Label style={{ color: '#e056fd', fontWeight: 'bold' }}>
            Mises en relations
          </Statistic.Label>
        </Statistic>
      </Statistic.Group>
      <div style={{ height: 500 }}>
        <ResponsiveBar
          colors={['#e056fd', '#f6ccfe']}
          groupMode="grouped"
          data={chartData}
          keys={['Mises en relations']}
          indexBy="Date"
          margin={{
            top: 30,
            bottom: 60,
            left: 25,
          }}
          padding={0.2}
          innerPadding={7}
          enableLabel={false}
          axisBottom={{
            tickRotation: 45,
          }}
        />
      </div>
    </Container>
  );
};

export default ProStatsNewContacts;
