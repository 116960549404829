import { useMutation, useQuery } from '@tanstack/react-query';
import { Formik } from 'formik';
import {
  authGetUserOptions,
  authUpdateUserMutation,
} from 'generated/api-client/@tanstack/react-query.gen';
import React, { FunctionComponent, ReactNode, useCallback } from 'react';
import { Loader, Modal } from 'semantic-ui-react';
import { ToastError, ToastSuccess } from 'utils';
import { AuthUserFormFields } from './auth-user-form-field.type';
import authUserFormValidationSchema from './auth-user-form-validation-schema';

interface AccountFormUpdateProps {
  userId: string;
  children?: ReactNode;
  onUserUpdated?: () => void;
}

const AccountFormUpdate: FunctionComponent<AccountFormUpdateProps> = ({
  userId: accountId,
  children,
  onUserUpdated,
}) => {
  const { data: account, isLoading } = useQuery(
    authGetUserOptions({ path: { userId: accountId } }),
  );

  const { mutate: updateAccount } = useMutation({
    ...authUpdateUserMutation(),
    onError: () => {
      ToastError(
        'Erreur',
        "Une erreur est survenue lors de la modification de l'adresse email",
      );
    },
    onSuccess: () => {
      onUserUpdated?.();
      ToastSuccess('Succès', 'Adresse email modifié');
    },
  });

  const onSubmit = useCallback(
    async (values: AuthUserFormFields) => {
      updateAccount({
        path: { userId: accountId },
        body: { email: values.email },
      });
    },
    [updateAccount],
  );

  if (isLoading) {
    return <Loader active inline="centered" />;
  }

  if (!account) {
    return (
      <Modal.Content>
        <p>
          Une erreur est survenue lors de la récupération des informations du
          compte. Veuillez réessayer.
        </p>
      </Modal.Content>
    );
  }

  const initialValues: AuthUserFormFields = {
    email: account.email,
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={authUserFormValidationSchema(account.email)}
    >
      {children}
    </Formik>
  );
};

export default AccountFormUpdate;
