import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';

const NewContactInfoPopup = () => {
  return (
    <Popup
      trigger={
        <Icon
          name="info circle"
          style={{
            marginLeft: '5px',
          }}
        />
      }
      content="Nombre de nouveaux particuliers ayant contacté le pro via appel, mail ou sms. Si un particulier n'a pas contacté le pro depuis plus de 4 mois il est de nouveau considéré comme nouveau."
    />
  );
};

export default NewContactInfoPopup;
