import React, {
  CSSProperties,
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import AuthUserSearchFilterContainer from './auth-user-search-filter-container';
import { SearchResultData } from 'semantic-ui-react';
import { useQuery } from '@tanstack/react-query';
import { authFindUsersOptions } from 'generated/api-client/@tanstack/react-query.gen';

type AuthUserSearchFilterRepositoryProps = {
  onResultSelect?: (
    event: React.MouseEvent<HTMLDivElement>,
    data: SearchResultData,
  ) => void;
  style?: CSSProperties;
  placeholder?: string;
};

const AuthUserSearchFilterRepository: FunctionComponent<
  AuthUserSearchFilterRepositoryProps
> = ({ onResultSelect, ...rest }) => {
  const [query, setQuery] = useState<string>('');

  const { data } = useQuery({
    ...authFindUsersOptions({
      query: {
        email: query,
        limit: 10,
        exact: false,
      },
    }),
    // Disable query when query is less than 3 characters
    enabled: query.length >= 3,
  });

  const fetchUsers = useCallback(async (criteria?: string) => {
    const search = criteria
      ?.normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();

    if (search) setQuery(search);
  }, []);

  const filteredData = useMemo(() => {
    return data?.filter((user) => !user.email.includes('+pro@bilik'));
  }, [data]);

  return (
    <AuthUserSearchFilterContainer
      users={filteredData || []}
      fetchUsers={fetchUsers}
      onResultSelect={onResultSelect}
      {...rest}
    />
  );
};

export default AuthUserSearchFilterRepository;
