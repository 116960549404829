import React, { FunctionComponent, useCallback } from 'react';
import { MongoEvent } from 'interfaces/events.interface';
import { ContactInfos, TimelineContainer } from './timeline-container';
import { TimelinePrivateIndividualQuery } from 'generated/graphql';
import Client from 'client';
import gql from 'graphql-tag';
import { ApolloQueryResult } from '@apollo/client';
import { apiClient } from 'axios-client';

type Props = {
  searchValue: string;
};

export type TimelineData = {
  [key: string]: MongoEvent[];
};

const TimelineRepository: FunctionComponent<Props> = ({ searchValue }) => {
  const fetchPrivateIndividuals = useCallback(
    async (
      searchValue: string,
    ): Promise<ApolloQueryResult<TimelinePrivateIndividualQuery>> => {
      return Client.query({
        query: gql`
          query timelinePrivateIndividualQuery($value: String!) {
            timelinePrivateIndividual(
              where: {
                _or: [
                  { email: { _ilike: $value } }
                  { telephone: { _eq: $value } }
                ]
              }
            ) {
              email
              telephone
              givenName
              familyName
              addressLocality
            }
          }
        `,
        variables: {
          value: searchValue,
        },
      });
    },
    [],
  );

  const fetchEvents = useCallback(
    async (filters: any, contactInfos: ContactInfos) =>
      await apiClient
        .post<MongoEvent[]>(`/event/pi-timeline`, {
          filters: {
            ...filters,
            eventTypes: filters?.eventTypes ? filters?.eventTypes : undefined, // Event types filter
          },
          contactInfos: contactInfos,
        })
        .then((response) => response.data),
    [],
  );

  return (
    <TimelineContainer
      fetchEvents={fetchEvents}
      searchValue={searchValue}
      fetchPrivateIndividuals={fetchPrivateIndividuals}
    />
  );
};
export default TimelineRepository;
