import { authCreateUser, authFindUsers, User } from 'generated/api-client';
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { ToastError, ToastSuccess } from 'utils';

type AccountEmailSuggestionsProps = {
  emailSuggestions: string[];
  onSuggestionAdded?: (accountId) => void;
};

const AccountEmailSuggestions: FunctionComponent<
  AccountEmailSuggestionsProps
> = ({ emailSuggestions, onSuggestionAdded }) => {
  const [users, setUsers] = useState<User[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchUsers = useCallback(async (emailSuggestions: string[]) => {
    setLoading(true);
    const users: User[] = [];
    for (const emailSuggestion of emailSuggestions) {
      const { data: user } = await authFindUsers({
        query: { email: emailSuggestion, exact: true, limit: 1 },
      });
      if (user && user.length > 0) users.push(user[0]);
    }
    setLoading(false);
    setUsers(users);
  }, []);

  // On value change check if old ids are equals to new ids (to avoid refreshing)
  useEffect(() => {
    fetchUsers(emailSuggestions);
  }, [emailSuggestions]);

  const addSuggestion = useCallback(
    async (emailSuggestion: string): Promise<void> => {
      if (!users) return;

      const user = users.filter(
        (account) => account.email === emailSuggestion,
      )[0];

      // Account already exist ?
      if (user) {
        onSuggestionAdded?.(user.id);
        return;
      }

      // If not create the user
      const { data: userId } = await authCreateUser({
        body: {
          email: emailSuggestion,
          groups: ['/pro-people/pros'],
        },
      });

      ToastSuccess('Succès', "Accès à l'espace pro créé");

      if (!userId) {
        ToastError('Erreur', "Impossible de créer l'utilisateur");
        throw new Error('User not created');
      }

      onSuggestionAdded?.(userId);
    },
    [users],
  );

  // Just return null when loading
  if (loading && !users) {
    return null;
  }

  // No suggestions
  if (emailSuggestions.length === 0) {
    return null;
  }

  return (
    <div style={{ marginTop: '10px' }}>
      {emailSuggestions.map((emailSuggestion, index) => (
        <Fragment key={`account-email-suggestion-${index}`}>
          <a
            href="#/"
            onClick={(): void => {
              addSuggestion(emailSuggestion);
            }}
          >
            Suggestion, ajouter {emailSuggestion}
          </a>
          <br />
        </Fragment>
      ))}
    </div>
  );
};

export default AccountEmailSuggestions;
