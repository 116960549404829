import { apiClient } from 'axios-client';
import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useFinancialStatsQueryLazyQuery } from 'generated/graphql';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Header, Segment, SegmentGroup, Statistic } from 'semantic-ui-react';
import DefaultPieChart from '../default-pie-chart';

interface FinancialStatsProps {
  bilikZoneIds?: number[];
  proViewIds?: number[];
}

const convertStatData = (data, entities, idKey, valueKey): ChartEntry[] => {
  return data.map((entry) => ({
    id: entry._id[idKey],
    label:
      entities?.find((entity) => entity.id === entry._id[idKey])?.name ??
      entry._id[idKey],
    value: entry[valueKey],
  }));
};

interface ChartEntry {
  id: string;
  label: string;
  value: number;
}

export const FinancialStats: FunctionComponent<FinancialStatsProps> = ({
  bilikZoneIds,
  proViewIds,
}) => {
  const [managerChartData, setManagerChartData] = useState<ChartEntry[]>([]);
  const [bilikZoneChartData, setBilikZoneChartData] = useState<ChartEntry[]>(
    [],
  );
  const [total, setTotal] = useState<number | null>(null);
  const month = useMemo(() => subMonths(new Date(), 1), []);
  const isBilikZoneChartVisible = !bilikZoneIds || bilikZoneIds.length >= 2;

  const [financialStatsQuery] = useFinancialStatsQueryLazyQuery();

  const fetchStatData = useCallback(
    async (filters?: { [key: string]: boolean | string }) => {
      return apiClient
        .post('event/stats', {
          after: startOfMonth(month),
          before: endOfMonth(month),
          bilikZoneIds,
          proViewIds,
          groupByMonth: true,
          ...filters,
        })
        .then((response) => response.data);
    },
    [month, bilikZoneIds, proViewIds],
  );

  useEffect(() => {
    fetchStatData().then(async (data) => {
      setTotal(data?.[0]?.totalBillingAmount);
    });

    fetchStatData({ groupByManagerId: true }).then(async (data) => {
      const result = await financialStatsQuery({
        variables: {
          bilikPersonIds: data.map((entry) => entry._id.managerId),
          bilikZoneIds: [],
        },
      });

      const managers =
        result.data?.bilikPeople.map((bilikPerson) => ({
          id: bilikPerson.id,
          name: `${bilikPerson.givenName} ${bilikPerson.familyName}`,
        })) ?? [];

      const chartData = convertStatData(
        data,
        managers,
        'managerId',
        'totalBillingManagerAmount',
      );

      setManagerChartData(chartData);
    });

    // Only when multiple bilikZone are active
    if (!isBilikZoneChartVisible) return;

    fetchStatData({ groupByBilikZoneId: true }).then(async (data) => {
      const result = await financialStatsQuery({
        variables: {
          bilikZoneIds: data.map((entry) => entry._id.bilikZoneId),
          bilikPersonIds: [],
        },
      });

      const chartData = convertStatData(
        data,
        result.data?.bilikZones,
        'bilikZoneId',
        'totalBillingAmount',
      );

      setBilikZoneChartData(chartData);
    });
  }, [proViewIds, bilikZoneIds]);

  return (
    <SegmentGroup>
      <Segment>
        <Header>Finance ({format(month, 'MM/yyyy')})</Header>
      </Segment>
      <Segment>
        <div style={{ textAlign: 'center' }}>
          <Statistic size="small" color="green">
            {total ? (
              <>
                <Statistic.Label>
                  Chiffre d&apos;affaire{' '}
                  {format(month, 'MMMM yyyy', { locale: fr })}
                </Statistic.Label>
                <Statistic.Value>
                  {total.toLocaleString('FR')} €
                </Statistic.Value>
              </>
            ) : null}
          </Statistic>
        </div>
      </Segment>
      {isBilikZoneChartVisible && (
        <>
          <Segment>
            <Header>Par zone</Header>
          </Segment>
          <Segment>
            <DefaultPieChart data={bilikZoneChartData} />
          </Segment>
          <Segment>
            <Header>Par responsable de réseau</Header>
          </Segment>
        </>
      )}
      <Segment>
        <DefaultPieChart data={managerChartData} />
      </Segment>
    </SegmentGroup>
  );
};
