import { authFindUsers } from 'generated/api-client';
import { ToastError } from 'utils';
import * as Yup from 'yup';

const isExistingEmail = async (email?: string | null): Promise<boolean> => {
  const { data: users } = await authFindUsers({
    query: { email: email ?? '', exact: true, limit: 1 },
  });
  return (users?.length || false) > 0;
};

export default (currentEmail?: string): Yup.ObjectSchema =>
  Yup.object().shape({
    email: Yup.string()
      .required('Adresse email requise !')
      .email('Adresse email invalide !')
      .test(
        'is-email-uniq',
        'Cette adresse email est déjà utilisé !',
        async (email) => {
          if (currentEmail !== email) {
            return await isExistingEmail(email)
              .then((res) => res === false || currentEmail === email)
              .catch((error) => {
                ToastError(
                  'Erreur',
                  "Impossible de vérifier l'unicité de l'adresse email",
                );
                throw error;
              });
          }
          return true;
        },
      ),
  });
