import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import env from 'env';
import {
  getRefreshToken,
  getToken,
  keycloak,
  setToken,
  setTokenExp,
} from 'utils';
import jwt from 'jsonwebtoken';
import { client as apiV2Client } from 'generated/api-client/client.gen';

apiV2Client.setConfig({
  baseURL: `${env.API_URL}/v2`,
});

// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest): Promise<void> => {
  const params = new URLSearchParams();
  params.append('grant_type', 'refresh_token');
  params.append('client_id', env.KEYCLOAK_CLIENT_ID);
  params.append('refresh_token', getRefreshToken() ?? '');

  return axios
    .post(
      `${keycloak.authServerUrl}/realms/${keycloak.realm}/protocol/openid-connect/token`,
      params,
      {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
      },
    )
    .then((tokenRefreshResponse) => {
      const data = tokenRefreshResponse.data;

      const decodedToken = jwt.decode(data.access_token) as { exp: number };
      setToken(data.access_token);
      setTokenExp(decodedToken.exp);

      failedRequest.response.config.headers['Authorization'] =
        'Bearer ' + data.access_token;

      return Promise.resolve();
    });
};

export const apiClient = axios.create({ baseURL: env.API_URL });

const authInterceptor = (config) => {
  const token = getToken();
  if (config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};

// Automatically add authorization header
apiClient.interceptors.request.use(authInterceptor);
apiV2Client.instance.interceptors.request.use(authInterceptor);

createAuthRefreshInterceptor(apiClient, refreshAuthLogic, {
  statusCodes: [401, 403],
});
createAuthRefreshInterceptor(apiV2Client.instance, refreshAuthLogic, {
  statusCodes: [401, 403],
});
