import { apiClient } from 'axios-client';
import { AuthUserSearchFilter } from 'components/auth-user-search-filter';
import AuthUserFormModal from 'components/auth-user-form-modal/auth-user-form-modal';
import ConfirmModal from 'components/confirm-modal/confirm-modal';
import FieldError from 'components/field-error/field-error';
import { getIn, useFormikContext } from 'formik';
import {
  authCreateUser,
  authFindUsers,
  authGetUser,
  User,
} from 'generated/api-client';
import {
  useAccountsFieldGetLinkedAccountLazyQuery,
  useAccountsFieldLinkAccountMutation,
  useAccountsFieldUnlinkAllAccountMutation,
} from 'generated/graphql';
import { useCurrentBilikPerson } from 'hooks/use-current-bilik-person/use-current-bilik-person';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Container,
  Divider,
  Dropdown,
  DropdownItem,
  Icon,
  Label as LabelUi,
  Loader,
  Message,
  Table,
} from 'semantic-ui-react';
import {
  getUserCredentials,
  getUserFederatedIdentity,
  ToastError,
  ToastInfo,
  ToastSuccess,
  ToastWarn,
} from 'utils';
import AccountEmailSuggestions from './account-email-suggestions/account-email-suggestions';
import { AccountProViewDeleteConfirmModal } from './account-pro-view-delete-confirm-modal';

type AccountsFieldProps = {
  name: string;
  currentProViewId?: number;
  accountEmailSuggestions?: string[];
};

type ModalState = {
  open: boolean;
  id?: string;
};

const AccountsField: FunctionComponent<AccountsFieldProps> = ({
  name,
  currentProViewId,
  accountEmailSuggestions,
}) => {
  const { values, touched, errors, setFieldValue, setFieldTouched } =
    useFormikContext();

  const { currentBilikPerson } = useCurrentBilikPerson();

  const [userModal, setUserModal] = useState<ModalState>({ open: false });

  const [
    accountProViewDeleteConfirmModal,
    setAccountProViewDeleteConfirmModal,
  ] = useState<ModalState>({
    open: false,
  });

  const accountIds = useMemo(() => getIn(values, name), [values, name]);
  const [users, setUsers] = useState<User[]>([]);

  const filterProTestUser = useCallback(
    (user) => !user.email.includes('+pro@bilik'),
    [],
  );

  const fetchUsers = useCallback(async (accountIds: string[]) => {
    const users: User[] = [];
    for (const accountId of accountIds) {
      const { data: user } = await authGetUser({ path: { userId: accountId } });

      users.push({
        id: user?.id ?? accountId,
        email: user?.email ?? 'null',
      });
    }
    setUsers(users.filter(filterProTestUser));
  }, []);

  // On value change check if old ids are equals to new ids (to avoid refreshing)
  useEffect(() => {
    fetchUsers(accountIds);
  }, [accountIds]);

  // Filtering suggestions to exclude already added emails
  const filteredAuthUserEmailSuggestions = useMemo(() => {
    if (users && accountEmailSuggestions) {
      const accountEmails = users.map((account) => account.email);
      return accountEmailSuggestions.filter(
        (accountEmailSuggestion) =>
          !accountEmails.includes(accountEmailSuggestion),
      );
    }
    return [];
  }, [users, accountEmailSuggestions]);

  const [linkAccountMutation] = useAccountsFieldLinkAccountMutation();
  const [unlinkAllAccountMutation] = useAccountsFieldUnlinkAllAccountMutation();
  const [getLinkedAccountQuery] = useAccountsFieldGetLinkedAccountLazyQuery();

  const handleLinkAuthUser = useCallback(
    async (userId: string) => {
      // Check if account is already linked, if so, do nothing and warn user
      if (accountIds.includes(userId)) {
        ToastWarn('Attention', 'Accès déjà présent sur la fiche');
        return;
      }

      if (currentProViewId) {
        try {
          await linkAccountMutation({
            variables: {
              objects: [{ authUserId: userId, proViewId: currentProViewId }],
            },
          });
          ToastSuccess('Succès', 'Accès enregistré sur la fiche');
        } catch (error) {
          console.error(error);
          ToastError('Erreur', "Impossible d'enregistrer l'accès sur la fiche");
        }
      }

      setFieldTouched(name);
      setFieldValue(name, [...accountIds, userId], true);
    },
    [accountIds, name, setFieldTouched, setFieldValue],
  );

  const handleLinkProTestUser = useCallback(
    async (impersonatedUserId: string) => {
      if (!currentBilikPerson) return;

      const [identifier, domain] = currentBilikPerson.email.split('@');
      const testEmail = `${identifier}+pro@${domain}`;

      // Search for existing test account
      const { data: users } = await authFindUsers({
        query: { email: testEmail, exact: true, limit: 1 },
      });

      const data = { testUserId: users?.[0]?.id };

      // Create keycloak account if not exist
      if (!data.testUserId) {
        ToastInfo(
          'Compte de test introuvable',
          `Création d'un nouveau compte de test...`,
        );

        const { data: testUserId } = await authCreateUser({
          body: {
            email: testEmail,
            groups: ['/pro-people/pros'],
            password: {
              value: 'bilik123test',
              temporary: true,
            },
          },
        });

        data.testUserId = testUserId;

        ToastWarn(
          'Compte de test créé',
          <>
            <p>
              Ton compte de test a été créé. Tu peux accéder à l&apos;espace pro
              avec les identifiants suivants :
            </p>
            <p>
              <u>Email</u> :<b> {testEmail}</b>
              <br />
              <u>Mot de passe :</u>
              <b>bilik123test</b>
            </p>
            <p>
              Il te sera demandé de changer ton mot de passe lors de ta première
              connexion.
            </p>
          </>,
        );
      }

      await unlinkAllAccountMutation({
        variables: {
          authUserId: data.testUserId,
        },
      });

      const result = await getLinkedAccountQuery({
        variables: { authUserId: impersonatedUserId },
      });

      const accountProView = result.data?.accountProView ?? [];

      await linkAccountMutation({
        variables: {
          objects: accountProView.map((accountProView) => ({
            proViewId: accountProView.proViewId,
            authUserId: data.testUserId,
          })),
        },
      });

      ToastWarn(
        <>
          Compte de test configuré sur
          <br />
          {impersonatedUserId}
        </>,
        <>
          <p>
            Connecte toi à l&apos;espace pro avec ton compte de test :{' '}
            <b>{testEmail}</b>
          </p>
          <p>
            <u>Rappel</u> : Déconnecte toi de l&apos;admin en amont ou utilise
            un onglet de navigation privé.
          </p>
        </>,
      );
    },
    [currentBilikPerson],
  );

  if (!users) {
    return (
      <Loader
        style={{ marginTop: '50px' }}
        size="large"
        active
        inline="centered"
      >
        Chargement...
      </Loader>
    );
  }

  return (
    <>
      {users && users.length > 0 ? (
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Statut</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {users.map((user) => {
              return (
                <Table.Row key={`accounts-field-${user.id}`}>
                  <Table.Cell>
                    {user.email === 'null' ? (
                      <LabelUi color="red">Introuvable dans Keycloak</LabelUi>
                    ) : (
                      user.email
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {user.email === 'null' ? (
                      user.id
                    ) : (
                      <KeycloakAccountStatus userId={user.id} />
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Dropdown
                      text="Actions"
                      labeled
                      button
                      floating
                      className="icon"
                    >
                      <Dropdown.Menu>
                        <ConfirmModal
                          trigger={
                            <DropdownItem
                              icon="envelope"
                              text="Création de compte"
                            />
                          }
                          header="Confirmation"
                          content={
                            <p>
                              Cette action va déclencher un envoi d&apos;email.
                              Continuer ?
                            </p>
                          }
                          onConfirm={async (): Promise<void> => {
                            const credentials = await getUserCredentials(
                              user.id,
                            );

                            const isPasswordCreated = !!credentials.find(
                              (credential) => credential.type === 'password',
                            );

                            if (isPasswordCreated) {
                              ToastInfo(
                                'Compte déjà créé',
                                'Un mot de passe a déjà été créé pour ce compte. En cas de perte utiliser l\'option : "Mot de passe oublié ?"',
                              );
                              return;
                            }

                            await apiClient
                              .post('/form/account/create-email', {
                                email: user.email,
                              })
                              .catch((error) => {
                                ToastError(
                                  'Erreur',
                                  "Impossible d'envoyer le mail de création de compte",
                                );
                                throw error;
                              });

                            ToastSuccess(
                              'Succès',
                              'Email de création de compte envoyé',
                            );
                          }}
                        />
                        <Dropdown.Item
                          icon="sign-in"
                          text="Espace pro"
                          onClick={() => handleLinkProTestUser(user.id)}
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                    <Button
                      icon
                      negative
                      type="button"
                      basic
                      style={{ minWidth: '35px', minHeight: '32px' }}
                      onClick={(): void => {
                        setAccountProViewDeleteConfirmModal({
                          open: true,
                          id: user.id,
                        });
                      }}
                    >
                      <Icon name="trash alternate outline" />
                    </Button>
                    <Button
                      icon
                      primary
                      style={{ minWidth: '35px', maxHeight: '32px' }}
                      type="button"
                      onClick={(): void => {
                        setUserModal({ open: true, id: user.id });
                      }}
                    >
                      <Icon name="pencil alternate" />
                    </Button>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      ) : (
        <Message>Aucun comptes</Message>
      )}
      <Container fluid textAlign="right">
        <AuthUserSearchFilter
          style={{ marginRight: '15px' }}
          placeholder="Email..."
          onResultSelect={(_, { result }): void => {
            handleLinkAuthUser(result.value);
          }}
        />
        <Button
          positive
          icon
          type="button"
          style={{ minWidth: '45px', minHeight: '32px' }}
          onClick={(): void => {
            setUserModal({ open: true });
          }}
        >
          <Icon name="plus" />
        </Button>
      </Container>
      <Container fluid textAlign="right">
        <AccountEmailSuggestions
          emailSuggestions={filteredAuthUserEmailSuggestions}
          onSuggestionAdded={handleLinkAuthUser}
        />
      </Container>
      {errors[name] && touched[name] ? (
        <FieldError>{errors[name]}</FieldError>
      ) : null}
      <Divider hidden />
      <AuthUserFormModal
        open={userModal.open}
        userId={userModal.id}
        onClose={(): void => {
          setUserModal({ open: false });
        }}
        onUserUpdated={() => fetchUsers(accountIds)}
        onUserCreated={handleLinkAuthUser}
      />
      {accountProViewDeleteConfirmModal.id ? (
        <AccountProViewDeleteConfirmModal
          open={accountProViewDeleteConfirmModal.open}
          onClose={(): void => {
            setAccountProViewDeleteConfirmModal({ open: false });
          }}
          onConfirm={(accountId): void => {
            setFieldTouched(name);
            setFieldValue(
              name,
              accountIds.filter(
                (accountValueId) => accountValueId !== accountId,
              ),
              true,
            );
            ToastInfo(
              'Accès retiré',
              currentProViewId ? 'Pense à enregistrer la fiche' : null,
            );
          }}
          authUserId={accountProViewDeleteConfirmModal.id}
          currentProViewId={currentProViewId}
        />
      ) : null}
    </>
  );
};

interface KeycloakAccountStatusProps {
  userId: string;
}

const KeycloakAccountStatus: FunctionComponent<KeycloakAccountStatusProps> = ({
  userId,
}) => {
  const [loading, setLoading] = useState(false);
  const [isPasswordcreated, setIsPasswordCreated] = useState<boolean>(false);
  const [isLinkedToGoogle, setIsLinkedToGoogle] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getUserCredentials(userId).then((credentials) => {
        setIsPasswordCreated(
          !!credentials.find((credential) => credential.type === 'password'),
        );
      }),
      getUserFederatedIdentity(userId).then((federatedIdentities) => {
        setIsLinkedToGoogle(
          !!federatedIdentities.find(
            (federatedIdentity) =>
              federatedIdentity.identityProvider === 'google',
          ),
        );
      }),
    ]).finally(() => {
      setLoading(false);
    });
  }, [userId]);

  if (loading)
    return (
      <>
        <Loader inline size="mini" active />
      </>
    );

  return (
    <>
      {isPasswordcreated && <LabelUi color="green">Compte créé</LabelUi>}
      {!isPasswordcreated && (
        <LabelUi color="orange">En attente de création</LabelUi>
      )}
      {isLinkedToGoogle && <LabelUi color="green">Lien Google établi</LabelUi>}
    </>
  );
};

export default AccountsField;
