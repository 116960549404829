import {
  Button,
  Dimmer,
  Icon,
  Label,
  Loader,
  Popup,
  Table,
} from 'semantic-ui-react';
import React, { FunctionComponent } from 'react';

import { BilikPeopleTableQuery } from 'generated/graphql';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { formatTelephone } from 'utils';

type BilikPeopleTableProps = {
  bilikPeople: BilikPeopleTableQuery['bilikPerson'];
  loading: boolean;
};

const BilikPeopleTableView: FunctionComponent<BilikPeopleTableProps> = ({
  bilikPeople,
  loading,
}) => {
  return (
    <Dimmer.Dimmable>
      <Dimmer inverted verticalAlign="top" active={loading}>
        <Loader style={{ marginTop: '20px' }} inline>
          Chargement...
        </Loader>
      </Dimmer>
      <Table striped celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Prénom Nom</Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Téléphone</Table.HeaderCell>
            <Table.HeaderCell>Date d&apos;embauche</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {bilikPeople.map((bilikPerson) => (
            <Table.Row key={bilikPerson.id}>
              <Table.Cell>
                <Icon
                  name="circle"
                  color={bilikPerson.status === 'archived' ? 'grey' : 'green'}
                  size="small"
                />
                &nbsp;
                {bilikPerson.givenName} {bilikPerson.familyName}
              </Table.Cell>
              <Table.Cell>
                {bilikPerson.roleName === 'admin' ? (
                  <Label color="red">Admin</Label>
                ) : (
                  <Label color="grey">Utilisateur</Label>
                )}
              </Table.Cell>
              <Table.Cell>{bilikPerson.email}</Table.Cell>
              <Table.Cell>
                {formatTelephone(bilikPerson.telephone || 'error')}
              </Table.Cell>
              <Table.Cell textAlign="center">
                {format(new Date(bilikPerson.employmentDate), 'dd/MM/yyyy')}
              </Table.Cell>
              <Table.Cell textAlign="right">
                <Link to={`/bilik_people/update/${bilikPerson.id}`}>
                  <Popup
                    content="Modifier"
                    position="top center"
                    trigger={
                      <Button primary icon>
                        <Icon name="pencil alternate" />
                      </Button>
                    }
                  />
                </Link>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Dimmer.Dimmable>
  );
};

export default BilikPeopleTableView;
