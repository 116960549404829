import {
  ProPresentationSetInput,
  ProViewInsertInput,
  useProFormCreateModalQueryLazyQuery,
  useProFormCreateProViewMutation,
  useProFormUpdateProPresentationMutation,
} from 'generated/graphql';
import React, { FunctionComponent, useCallback } from 'react';
import ProFormCreateModalContainer from './pro-form-create-modal-container';

type ProFormCreateModalRepositoryProps = ProFormCreateModalProps & {
  proPresentationId?: number;
  onProCreated?: (proViewId: number) => void;
};

export type ProFormCreateModalProps = {
  open: boolean;
  onClose?: () => void;
};

const ProFormCreateModalRepository: FunctionComponent<
  ProFormCreateModalRepositoryProps
> = ({ ...rest }) => {
  const [proFormCreateModalQuery, { data }] =
    useProFormCreateModalQueryLazyQuery();

  const fetchProPresentation = useCallback(async (id: number) => {
    await proFormCreateModalQuery({ variables: { id } });
  }, []);

  const [createProViewMutation] = useProFormCreateProViewMutation();

  const createProView = useCallback(async (proView: ProViewInsertInput) => {
    const response = await createProViewMutation({
      variables: { input: proView },
    });
    return response.data?.insertProViewOne;
  }, []);

  const [updateProPresentationMutation] =
    useProFormUpdateProPresentationMutation();

  const updateProPresentation = useCallback(
    async (id: number, proPresentation: ProPresentationSetInput) => {
      const response = await updateProPresentationMutation({
        variables: {
          id,
          input: proPresentation,
        },
      });
      return response.data?.updateProPresentationByPk;
    },
    [],
  );

  return (
    <ProFormCreateModalContainer
      proPresentation={data?.proPresentationByPk}
      fetchProPresentation={fetchProPresentation}
      createProView={createProView}
      updateProPresentation={updateProPresentation}
      {...rest}
    />
  );
};

export default ProFormCreateModalRepository;
