import { useFormikContext } from 'formik';
import React, { FunctionComponent } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { AuthUserFormFields } from './auth-user-form-field.type';
import AuthUserFormView from './auth-user-form-view';
import AuthUserFormUpdate from './auth-user-form-update';
import AuthUserFormCreate from './auth-user-form-create';

interface ActionsProps {
  onClose?: () => void;
}

const Actions: FunctionComponent<ActionsProps> = ({ onClose }) => {
  const { submitForm, isSubmitting } = useFormikContext<AuthUserFormFields>();
  return (
    <>
      <Button onClick={onClose}>Annuler</Button>
      <Button
        primary
        disabled={isSubmitting}
        loading={isSubmitting}
        type="button"
        onClick={submitForm}
      >
        <Icon name="save" />
        Enregistrer
      </Button>
    </>
  );
};

interface AuthUserFormModalProps {
  userId?: string;
  open: boolean;
  onClose: () => void;
  onUserUpdated?: () => void;
  onUserCreated?: (userId: string) => void;
}

const AuthUserFormModal: FunctionComponent<AuthUserFormModalProps> = ({
  userId,
  onUserCreated,
  onUserUpdated,
  open,
  onClose,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      closeIcon
    >
      <Modal.Header>
        {userId ? `Modifier l'accès espace pro` : `Créer l'accès espace pro`}
      </Modal.Header>
      {userId && (
        <AuthUserFormUpdate
          userId={userId}
          onUserUpdated={() => {
            onUserUpdated?.();
            onClose?.();
          }}
        >
          <>
            <Modal.Content>
              <AuthUserFormView />
            </Modal.Content>
            <Modal.Actions>
              <Actions onClose={onClose} />
            </Modal.Actions>
          </>
        </AuthUserFormUpdate>
      )}
      {!userId && (
        <AuthUserFormCreate
          onUserCreated={(id) => {
            onUserCreated?.(id);
            onClose?.();
          }}
        >
          <>
            <Modal.Content>
              <AuthUserFormView />
            </Modal.Content>
            <Modal.Actions>
              <Actions onClose={onClose} />
            </Modal.Actions>
          </>
        </AuthUserFormCreate>
      )}
    </Modal>
  );
};

export default AuthUserFormModal;
