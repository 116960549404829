import {
  useCurrentBilikPersonQueryLazyQuery,
  CurrentBilikPersonQuery,
} from 'generated/graphql';
import { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';

export const useCurrentBilikPerson = (): {
  currentBilikPerson?: CurrentBilikPersonQuery['bilikPerson'][0];
  loading: boolean;
} => {
  const { keycloak } = useKeycloak();

  const [currentBilikPersonQuery, { data, loading }] =
    useCurrentBilikPersonQueryLazyQuery();

  useEffect(() => {
    if (keycloak.tokenParsed) {
      currentBilikPersonQuery({
        variables: {
          authUserId: keycloak.tokenParsed['sub'],
        },
      });
    }
  }, [keycloak.tokenParsed]);

  if (!keycloak.tokenParsed) {
    return {
      currentBilikPerson: undefined,
      loading: false,
    };
  }

  return {
    currentBilikPerson: data?.bilikPerson[0],
    loading,
  };
};
