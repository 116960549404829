import React, { FunctionComponent } from 'react';
import { Serie } from '@nivo/line';
import DefaultLineChart from '../default-line-chart';

type ProReviewInvitationsGraphViewProps = {
  data: Serie[];
};

export const ProReviewInvitationsGraphView: FunctionComponent<
  ProReviewInvitationsGraphViewProps
> = ({ data }) => {
  return <DefaultLineChart data={data} colors={['#6C892A', '#9BC53D']} />;
};
