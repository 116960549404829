import React, { FunctionComponent } from 'react';
import { Card } from 'semantic-ui-react';
import { BackupCard } from './backup-card/backup-card';
import { InvoicesAnomalyDetectorCard } from './invoice-anomaly-detector-card.tsx/invoices-anomaly-detector-card';
import { ExportGuideCard } from './export-guide-card/export-guide-card';
import { ZohoConfigurationCard } from './zoho-configuration-card/zoho-configuration-card';
import { ExportSepaCard } from './export-sepa-card/export-sepa-card';
import Content from 'components/content/content';
import ContentHeader from 'components/content-header/content-header';
import ContentHeaderTitle from 'components/content-header-title/content-header-title';
import ContentBody from 'components/content-body/content-body';
import ContentContainer from 'components/content-container/content-container';
import { SearchEngineUpdateCard } from './search-engine-update-card/search-engine-update-card';
import { RefreshMaterializedViewsCard } from './refresh-materialized-views-card/refresh-materialized-views-card';
import { ProInterventionUpdateCard } from './pro-intervention-update-card/pro-intervention-update-card';

const ToolsPage: FunctionComponent = () => (
  <Content>
    <ContentHeader>
      <ContentHeaderTitle>Outils</ContentHeaderTitle>
    </ContentHeader>
    <ContentBody>
      <ContentContainer>
        <Card.Group>
          <BackupCard />
          <RefreshMaterializedViewsCard />
          <InvoicesAnomalyDetectorCard />
          <ExportGuideCard />
          <ZohoConfigurationCard />
          <ExportSepaCard />
          <SearchEngineUpdateCard />
          <ProInterventionUpdateCard />
        </Card.Group>
      </ContentContainer>
    </ContentBody>
  </Content>
);

export default ToolsPage;
