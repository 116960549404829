import { AccountProViewDeleteConfirmModalQuery } from 'generated/graphql';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import AccountProViewDeleteConfirmModalView, {
  AccountProViewDeleteConfirmModalProps,
} from './account-pro-view-delete-confirm-modal-view';

type AccountProViewDeleteConfirmModalContainerProps =
  AccountProViewDeleteConfirmModalProps & {
    currentProViewId?: number;
    data?: AccountProViewDeleteConfirmModalQuery;
    onConfirm?: (authUserId: string) => void;
    authUserId: string;
  };

const AccountProViewDeleteConfirmModalContainer: FunctionComponent<
  AccountProViewDeleteConfirmModalContainerProps
> = ({ data, currentProViewId, onClose, onConfirm, authUserId, ...rest }) => {
  const confirmDelete = useCallback(() => {
    onConfirm?.(authUserId);
    onClose?.();
  }, [onClose, onConfirm]);

  const proViews = useMemo(
    () => data?.proView.filter((proView) => proView.id !== currentProViewId),
    [data?.proView],
  );

  // If no others proViews linked then auto confirm
  useEffect(() => {
    if (proViews && proViews.length === 0) {
      onConfirm?.(authUserId);
      onClose?.();
    }
  }, [proViews]);

  // Loading state
  if (!proViews) return null;

  return (
    <AccountProViewDeleteConfirmModalView
      proViews={proViews}
      confirmDelete={confirmDelete}
      onClose={onClose}
      {...rest}
    />
  );
};

export default AccountProViewDeleteConfirmModalContainer;
