import * as Yup from 'yup';
import { ToastError } from 'utils';
import Client from 'client';
import gql from 'graphql-tag';
import { isValidFrTelephone } from 'utils/telephone';

const passwordRequired = Yup.string()
  .required('Un mot de passe est requis !')
  .min(8, 'Doit contenir 6 caractères minimum !');

const passwordNotRequired = Yup.string().min(
  8,
  'Doit contenir 8 caractères minimum !',
);

const isExistingEmail = async (email?: string | null): Promise<boolean> => {
  const result = await Client.query({
    query: gql`
      query ($email: String!) {
        bilikPerson(where: { email: { _eq: $email } }) {
          id
          email
        }
      }
    `,
    variables: { email: email || '' },
  });

  return result.data.bilikPerson && result.data.bilikPerson.length > 0;
};

type BilikPersonFormValidationSchemaArgs = {
  isCreate: boolean;
  currentEmail?: string;
};

export const bilikPersonFormValidationSchema = (
  args: BilikPersonFormValidationSchemaArgs,
): Yup.ObjectSchema => {
  const { currentEmail, isCreate } = args;

  return Yup.object().shape({
    roleName: Yup.string().required('Un rôle est requis !'),
    givenName: Yup.string().required('Prénom requis !'),
    familyName: Yup.string().required('Nom requis !'),
    telephone: Yup.string()
      .test('is-valid', 'Numéro de téléphone invalide !', isValidFrTelephone)
      .required('Numéro de téléphone requis !'),
    employmentDate: Yup.date().required("La date de d'embauche est requise !"),
    status: Yup.string().required('Un statut est requis !'),
    newPassword: isCreate === true ? passwordRequired : passwordNotRequired,
    email: Yup.string()
      .required('Adresse email requise !')
      .email('Adresse email non valide !')
      .test(
        'is-email-uniq',
        'Un utilisateur avec cette adresse email existe déjà !',
        async (email) => {
          if (currentEmail !== email) {
            return await isExistingEmail(email)
              .then((res) => res === false)
              .catch((error) => {
                ToastError(
                  'Erreur',
                  "Impossible de vérifier l'unicité de l'adresse email !",
                );
                throw error;
              });
          }
          return true;
        },
      ),
  });
};
