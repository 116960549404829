import FormField, {
  FormFieldSelectProps,
} from 'components/form-field/form-field';
import HelpText from 'components/help-text/help-text';
import Label from 'components/label/label';
import { useFormikContext } from 'formik';
import {
  SelectBilikPersonFieldQuery,
  useSelectBilikPersonFieldQuery,
} from 'generated/graphql';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import {
  DropdownItemProps,
  Select,
  FormField as FormFieldUi,
} from 'semantic-ui-react';

type BilikPersonSelectEntity = SelectBilikPersonFieldQuery['bilikPerson'][0] & {
  relayEmail: string;
};

type SelectBilikPersonFieldProps = Omit<
  FormFieldSelectProps,
  'options' | 'type' | 'loading'
> & {
  filters?: {
    proViewId?: number;
    status?: 'published' | 'archived';
    exceptIds?: number[];
  };
  valueType?: 'id' | 'email' | 'relayEmail' | 'telephone';
  additionalOptions?: DropdownItemProps[];
  onBilikPeopleLoaded?: (bilikPeople: BilikPersonSelectEntity[]) => void;
  customValue?: string;
};

const SelectBilikPersonField: FunctionComponent<
  SelectBilikPersonFieldProps
> = ({
  multiple,
  filters,
  valueType = 'id',
  additionalOptions,
  onBilikPeopleLoaded,
  customValue,
  ...rest
}) => {
  const { errors } = useFormikContext();

  const { data, loading, error } = useSelectBilikPersonFieldQuery({
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        ...(filters?.proViewId
          ? {
              managedBilikZones: {
                bilikZone: { proViews: { id: { _eq: filters.proViewId } } },
              },
            }
          : {}),
        ...(filters?.status ? { status: { _eq: filters.status } } : {}),
      },
    },
  });

  const bilikPeople: BilikPersonSelectEntity[] = useMemo(() => {
    if (!data) {
      return [];
    }

    const bilikPeople =
      filters?.exceptIds?.length ?? 0 > 0
        ? data.bilikPerson.filter(
            (bilikPerson) => !filters?.exceptIds?.includes(bilikPerson.id),
          )
        : data.bilikPerson;

    return bilikPeople.map((bilikPerson) => {
      const emailSplitted = bilikPerson.email?.split('@');

      const relayEmail = `${emailSplitted?.[0]}+relai@${emailSplitted?.[1]}`;

      return {
        ...bilikPerson,
        relayEmail,
      };
    });
  }, [data, filters?.exceptIds]);

  useEffect(() => {
    if (bilikPeople?.length && onBilikPeopleLoaded) {
      onBilikPeopleLoaded(bilikPeople);
    }
  }, [bilikPeople]);

  const options: DropdownItemProps[] = useMemo(() => {
    const options: DropdownItemProps[] = [];

    if (bilikPeople) {
      options.push(
        ...bilikPeople.map((bilikPerson) => ({
          value: bilikPerson[valueType],
          key: bilikPerson.id,
          text: `${bilikPerson.familyName} ${bilikPerson.givenName}`,
        })),
      );
    }

    if (additionalOptions) {
      options.push(...additionalOptions);
    }

    return options;
  }, [bilikPeople, valueType, additionalOptions]);

  const placeholder = useMemo(() => {
    if (loading) {
      return 'Chargement des utilisateurs...';
    }
    if (multiple) {
      return 'Selectionnez des utilisateurs...';
    }
    return 'Selectionnez un utilisateur...';
  }, [loading, multiple]);

  if (error) return <>Error! {error.message}</>;

  if (customValue) {
    return (
      <FormFieldUi>
        <Label required={rest.required}>{rest.label}</Label>
        <HelpText>{rest.helpText}</HelpText>

        <Select
          placeholder="Selectionnez un utilisateur..."
          error={errors.hasOwnProperty(rest.name)}
          options={options}
          value={customValue}
          onChange={rest.onChange}
        />
      </FormFieldUi>
    );
  }

  return (
    <FormField
      type="select"
      disabled={loading}
      loading={loading}
      options={options}
      placeholder={placeholder}
      multiple={multiple}
      {...rest}
    />
  );
};

export default SelectBilikPersonField;
