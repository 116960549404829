import { SolicitationsTableQuery } from 'generated/graphql';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { Unpacked } from 'utils/unpacked';
import { ProBouncedSolicitationView } from './pro-bounced-solicitation-view';
import Client from 'client';
import gql from 'graphql-tag';

type ProBouncedSolicitationContainerProps = {
  resendMailToPro: (uuid: string) => Promise<void>;
  emailTarget: string;
  reactivateEmailInPostmarkRequest: () => Promise<void>;
  refetchSolicitations: () => void;
  solicitation: Unpacked<SolicitationsTableQuery['solicitation']>;
};

export const ProBouncedSolicitationContainer: FunctionComponent<
  ProBouncedSolicitationContainerProps
> = ({
  resendMailToPro,
  emailTarget,
  reactivateEmailInPostmarkRequest,
  solicitation,
}) => {
  const [isExplanationModalOpen, setIsExplanationModalOpen] = useState(false);
  const [accountModalOpen, setAccountModalOpen] = useState(false);
  const [isActionInProgress, setIsActionInProgress] = useState(false);

  const reactivateEmailInPostmark = useCallback(async () => {
    setIsActionInProgress(true);
    await reactivateEmailInPostmarkRequest();
    setIsActionInProgress(false);
    setIsExplanationModalOpen(false);
  }, [reactivateEmailInPostmarkRequest]);

  const resendSolicitationToPro = useCallback(
    async (emailTargetModified: string) => {
      setIsActionInProgress(true);

      await Client.mutate({
        mutation: gql`
          mutation ($proViewId: Int!, $emailTarget: String!) {
            updateProViewByPk(
              _set: { emailTarget: $emailTarget }
              pkColumns: { id: $proViewId }
            ) {
              id
            }
          }
        `,
        variables: {
          proViewId: solicitation.proView.id,
          emailTarget: emailTargetModified,
        },
      });

      if (solicitation.uuid) {
        await resendMailToPro(solicitation.uuid).then(() => {
          setIsActionInProgress(false);
          setIsExplanationModalOpen(false);
          setAccountModalOpen(false);
        });
      }
    },
    [resendMailToPro, solicitation],
  );

  return (
    <ProBouncedSolicitationView
      reactivateEmailInPostmark={reactivateEmailInPostmark}
      emailTarget={emailTarget}
      resendSolicitationsToPro={resendSolicitationToPro}
      isExplanationModalOpen={isExplanationModalOpen}
      setIsExplanationModalOpen={setIsExplanationModalOpen}
      accountModalOpen={accountModalOpen}
      setAccountModalOpen={setAccountModalOpen}
      isActionInProgress={isActionInProgress}
    />
  );
};
