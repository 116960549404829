import { ResponsivePie } from '@nivo/pie';
import React from 'react';
import { Loader } from 'semantic-ui-react';

interface PieChartProps<T> {
  data: T[];
}

const DefaultPieChart = <T,>({ data }: PieChartProps<T>) => (
  <div style={{ height: 400, textAlign: 'center' }}>
    {data.length > 0 ? (
      <ResponsivePie
        data={data}
        margin={{ top: 40, right: 250, bottom: 40, left: 40 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        colors={{ scheme: 'nivo' }}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        valueFormat={(value) => `${value.toLocaleString('fr-FR')} €`}
        arcLabelsSkipAngle={15}
        enableArcLinkLabels={false}
        activeOuterRadiusOffset={5}
        tooltip={({ datum }) => {
          return (
            <div
              style={{
                background: 'white',
                color: 'inherit',
                fontSize: 'inherit',
                borderRadius: '2px',
                boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
                padding: '5px 9px',
              }}
            >
              <strong>{datum.label}</strong>
              <br />
              {datum.value.toLocaleString('fr-FR')} €
            </div>
          );
        }}
        legends={[
          {
            anchor: 'right',
            direction: 'column',
            justify: false,
            translateX: 90,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: '#999',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000',
                },
              },
            ],
          },
        ]}
      />
    ) : (
      <div style={{ paddingTop: '175px' }}>
        <Loader size="large" active inline />
      </div>
    )}
  </div>
);

export default DefaultPieChart;
