import Client from 'client';
import gql from 'graphql-tag';
import { ToastError } from 'utils';
import * as Yup from 'yup';

const isExistingSlug = async (slug?: string | null): Promise<boolean> => {
  return Client.query({
    query: gql`
      query ($slug: String!) {
        proPresentation(where: { slug: { _eq: $slug } }) {
          id
          slug
        }
      }
    `,
    variables: { slug: slug || '' },
  }).then(
    (response) =>
      response.data.proPresentation && response.data.proPresentation.length > 0,
  );
};

export const proFormCreateValidationSchema = (
  action: 'create' | 'create-zone',
): Yup.ObjectSchema => {
  return Yup.object().shape({
    bilikZoneId: Yup.number().nullable().required(),
    trades: Yup.array().of(Yup.string()).min(1),
    emailTarget: Yup.string()
      .email('Adresse email non valide')
      .required('Adresse mail requise !'),
    geoCoordinates: Yup.object()
      .shape({
        lat: Yup.number(),
        lng: Yup.number(),
      })
      .nullable()
      .required('Coordonnées requises !'),
    proPresentation:
      action === 'create'
        ? Yup.object().shape({
            name: Yup.string().required('Le nom est requis !'),
            mainTradeId: Yup.number().nullable().required(),
            slug: Yup.string()
              .required('Slug requis !')
              .matches(/^[a-z0-9-]+$/, {
                excludeEmptyString: true,
                message: 'Format incorrect !',
              })
              .test('is-slug-uniq', 'Slug déjà existant !', async (slug) => {
                return isExistingSlug(slug)
                  .then((res) => res === false)
                  .catch((error) => {
                    ToastError(
                      'Erreur',
                      "Impossible de vérifier l'unicité du slug !",
                    );
                    throw error;
                  });
              }),
          })
        : Yup.object(),
    postalAddress: Yup.object().shape({
      geoCoordinates: Yup.object()
        .shape({
          lat: Yup.number(),
          lng: Yup.number(),
        })
        .nullable()
        .required("Coordonnées de l'adresse de l'entreprise requises !"),
    }),
  });
};
